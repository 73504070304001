import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import passport from "../../assets/icon-passport.png";
import passportColor from "../../assets/icon-passport-color.png";
import nationalId from "../../assets/icon-national-id.png";
import nationalIdColor from "../../assets/icon-national-id-color.png";
import license from "../../assets/icon-licence.png";
import licenseColor from "../../assets/icon-licence-color.png";
import vectorPassport from "../../assets/vector-passport.png";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";

const KycForm = () => {
  const [passportSelected, setPassportSelected] = useState(true);
  const [nationalIdSelected, setNationalIdSelected] = useState(false);
  const [licenseSelected, setLicenseSelected] = useState(false);
  const [chosenId, setChosenId] = useState("Passport");
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [noSelectedFile, setNoSelectedFile] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameFieldEmpty, setFirstNameFieldEmpty] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameFieldEmpty, setLastNameFieldEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [number, setNumber] = useState("");
  const [numberFieldEmpty, setNumberFieldEmpty] = useState(false);
  const [dob, setDob] = useState("");
  const [dobFieldEmpty, setDobFieldEmpty] = useState(false);
  const [addressOne, setAddressOne] = useState("");
  const [addressOneFieldEmpty, setAddressOneFieldEmpty] = useState(false);
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [cityFieldEmpty, setCityFieldEmpty] = useState(false);
  const [state, setState] = useState("");
  const [stateFieldEmpty, setStateFieldEmpty] = useState(false);
  const [nationality, setNationality] = useState("United States");
  const [nationalityFieldEmpty, setNationalityFieldEmpty] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [zipCodeFieldEmpty, setZipCodeFieldEmpty] = useState(false);
  const [read, setRead] = useState(false);
  const [readUnchecked, setReadUnchecked] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [personalUnchecked, setPersonalUnchecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showNoUserFound = () => {
    toast.error("No user found", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      setFileUploaded(true);
      setNoSelectedFile(false);
    } else {
      setSelectedFile(null);
      setNoSelectedFile(true);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setFileUploaded(true);
      setNoSelectedFile(false);
    } else {
      setSelectedFile(null);
      setNoSelectedFile(true);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const cancelUpload = () => {
    setFileUploaded(false);
    setSelectedFile(null);
  };

  const selectPassport = () => {
    setPassportSelected(true);
    setNationalIdSelected(false);
    setLicenseSelected(false);
    setChosenId("Passport");
  };

  const selectNationalId = () => {
    setNationalIdSelected(true);
    setPassportSelected(false);
    setLicenseSelected(false);
    setChosenId("National ID");
  };

  const selectLicense = () => {
    setLicenseSelected(true);
    setPassportSelected(false);
    setNationalIdSelected(false);
    setChosenId("Driver's License");
  };

  const toggleReadBox = () => {
    setRead((prevState) => !prevState);
  };

  const togglePersonalBox = () => {
    setPersonal((prevState) => !prevState);
  };

  const submitKyc = (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!firstName) {
      setFirstNameFieldEmpty(true);
      return;
    } else {
      setFirstNameFieldEmpty(false);
    }

    if (!lastName) {
      setLastNameFieldEmpty(true);
      return;
    } else {
      setLastNameFieldEmpty(false);
    }

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!number) {
      setNumberFieldEmpty(true);
      return;
    } else {
      setNumberFieldEmpty(false);
    }

    if (!dob) {
      setDobFieldEmpty(true);
      return;
    } else {
      setDobFieldEmpty(false);
    }

    if (!addressOne) {
      setAddressOneFieldEmpty(true);
      return;
    } else {
      setAddressOneFieldEmpty(false);
    }

    if (!city) {
      setCityFieldEmpty(true);
      return;
    } else {
      setCityFieldEmpty(false);
    }

    if (!state) {
      setStateFieldEmpty(true);
      return;
    } else {
      setStateFieldEmpty(false);
    }

    if (!nationality) {
      setNationalityFieldEmpty(true);
      return;
    } else {
      setNationalityFieldEmpty(false);
    }

    if (!zipCode) {
      setZipCodeFieldEmpty(true);
      return;
    } else {
      setZipCodeFieldEmpty(false);
    }

    if (!selectedFile) {
      setNoSelectedFile(true);
      return;
    } else {
      setNoSelectedFile(false);
    }

    if (!read) {
      setReadUnchecked(true);
      return;
    } else {
      setReadUnchecked(false);
    }

    if (!personal) {
      setPersonalUnchecked(true);
      return;
    } else {
      setPersonalUnchecked(false);
    }

    const fileName = new Date().getTime() + selectedFile.name;
    const storage = getStorage(app);
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setLoading(true);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setLoading(false);
        showError();
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const sendKyc = async () => {
            const { data } = await axios.post(
              "https://nextera-api-new.onrender.com/api/kyc-new",
              {
                firstName:
                  firstName.charAt(0).toUpperCase() + firstName.slice(1),
                lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
                email,
                number,
                dob,
                addressOne,
                addressTwo,
                city,
                state,
                nationality,
                zipCode,
                document: downloadURL,
                id: chosenId,
              }
            );

            if (data === "No user found") {
              showNoUserFound();
              setLoading(false);
              return;
            } else {
              setLoading(false);
              navigate("/kyc-thank-you");
            }
          };

          sendKyc();
        });
      }
    );
  };

  return (
    <div className="page-user bg-light">
      <div className="page-ath-header">
        <Link to="/" className="page-ath-logo">
          <img src={logo} alt="logo" className="mx-auto" />
        </Link>
      </div>
      <div className="page-header page-header-kyc">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center">
              <h2 className="page-title">Begin your ID Verification</h2>
              <p className="large">Verify your identity</p>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={submitKyc}>
        <div className="page-content">
          <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-9">
                <div className="kyc-form-steps card mx-lg-4">
                  <div className="form-step form-step1">
                    <div className="form-step-head card-innr">
                      <div className="step-head">
                        <div className="step-number">01</div>
                        <div className="step-head-text">
                          <h4>Personal Details</h4>
                          <p>
                            Your simple personal information required for
                            identification
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-step-fields card-innr">
                      <div className="note note-plane note-light-alt note-md pdb-1x">
                        <em className="fas fa-info-circle" />
                        <p>
                          Please type carefully and fill out the form with your
                          personal details. Your can’t edit these details once
                          you've submitted the form
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                setFirstNameFieldEmpty(false);
                              }}
                            />
                            {firstNameFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                                setLastNameFieldEmpty(false);
                              }}
                            />
                            {lastNameFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Email Address
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value.toLowerCase());
                                setEmailFieldEmpty(false);
                              }}
                            />
                            {emailFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                Please enter a valid email address
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Phone Number
                              <span className="text-danger">*</span>
                            </label>
                            <PhoneInput
                              className="input-bordered outline-0"
                              value={number}
                              onChange={(number) => setNumber(number)}
                            />
                            {numberFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Date of Birth
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered date-picker"
                              type="date"
                              value={dob}
                              onChange={(e) => {
                                setDob(e.target.value);
                                setDobFieldEmpty(false);
                              }}
                            />
                            {dobFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-secondary mgt-0-5x">Your Address</h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Address Line 1
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={addressOne}
                              onChange={(e) => {
                                setAddressOne(e.target.value);
                                setAddressOneFieldEmpty(false);
                              }}
                            />
                            {addressOneFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Address Line 2
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={addressTwo}
                              onChange={(e) => {
                                setAddressTwo(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                                setCityFieldEmpty(false);
                              }}
                            />
                            {cityFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              State <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                                setStateFieldEmpty(false);
                              }}
                            />
                            {stateFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label block">
                              Nationality <span className="text-danger">*</span>
                            </label>
                            <CountryDropdown
                              className="input-bordered min-w-[305px]"
                              preferredCountries={["us"]}
                              value={nationality}
                              handleChange={(e) => {
                                setNationality(e.target.value);
                              }}
                            />
                            {nationalityFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Zip Code <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="number"
                              value={zipCode}
                              onChange={(e) => {
                                setZipCode(e.target.value);
                                setZipCodeFieldEmpty(false);
                              }}
                            />
                            {zipCodeFieldEmpty && (
                              <p className="mt-3 text-red text-sm">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-step form-step2">
                    <div className="form-step-head card-innr">
                      <div className="step-head">
                        <div className="step-number">02</div>
                        <div className="step-head-text">
                          <h4>Document Upload</h4>
                          <p>
                            To verify your identity, please upload any of your
                            documents
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-step-fields card-innr">
                      <div className="note note-plane note-light-alt note-md pdb-0-5x">
                        <em className="fas fa-info-circle" />
                        <p>
                          In order to complete, please upload any of the
                          following personal documents
                        </p>
                      </div>
                      <div className="gaps-2x" />
                      <ul
                        className="nav nav-tabs nav-tabs-bordered row flex-wrap guttar-20px"
                        role="tablist"
                      >
                        {passportSelected ? (
                          <li className="nav-item flex-grow-0">
                            <button
                              type="button"
                              onClick={selectPassport}
                              className="nav-link d-flex align-items-center active w-full"
                              data-toggle="tab"
                            >
                              <div className="nav-tabs-icon">
                                <img src={passport} alt="icon" />
                                <img src={passportColor} alt="icon" />
                              </div>
                              <span>Passport</span>
                            </button>
                          </li>
                        ) : (
                          <li className="nav-item flex-grow-0">
                            <button
                              type="button"
                              onClick={selectPassport}
                              className="nav-link d-flex align-items-center w-full"
                              data-toggle="tab"
                            >
                              <div className="nav-tabs-icon">
                                <img src={passport} alt="icon" />
                                <img src={passportColor} alt="icon" />
                              </div>
                              <span>Passport</span>
                            </button>
                          </li>
                        )}
                        {nationalIdSelected ? (
                          <li className="nav-item flex-grow-0 active">
                            <button
                              type="button"
                              onClick={selectNationalId}
                              className="nav-link d-flex align-items-center active w-full"
                              data-toggle="tab"
                            >
                              <div className="nav-tabs-icon">
                                <img src={nationalId} alt="icon" />
                                <img src={nationalIdColor} alt="icon" />
                              </div>
                              <span>National ID Card</span>
                            </button>
                          </li>
                        ) : (
                          <li className="nav-item flex-grow-0">
                            <button
                              type="button"
                              onClick={selectNationalId}
                              className="nav-link d-flex align-items-center w-full"
                              data-toggle="tab"
                            >
                              <div className="nav-tabs-icon">
                                <img src={nationalId} alt="icon" />
                                <img src={nationalIdColor} alt="icon" />
                              </div>
                              <span>National ID Card</span>
                            </button>
                          </li>
                        )}
                        {licenseSelected ? (
                          <li className="nav-item flex-grow-0">
                            <button
                              type="button"
                              onClick={selectLicense}
                              className="nav-link d-flex align-items-center active w-full"
                              data-toggle="tab"
                            >
                              <div className="nav-tabs-icon">
                                <img src={license} alt="icon" />
                                <img src={licenseColor} alt="icon" />
                              </div>
                              <span>Driver’s License</span>
                            </button>
                          </li>
                        ) : (
                          <li className="nav-item flex-grow-0">
                            <button
                              type="button"
                              onClick={selectLicense}
                              className="nav-link d-flex align-items-center w-full"
                              data-toggle="tab"
                            >
                              <div className="nav-tabs-icon">
                                <img src={license} alt="icon" />
                                <img src={licenseColor} alt="icon" />
                              </div>
                              <span>Driver’s License</span>
                            </button>
                          </li>
                        )}
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="passport"
                        >
                          <h5 className="text-secondary font-bold mb-2">
                            To avoid delays when verifying account, Please make
                            sure below:
                          </h5>
                          <ul className="list-check">
                            <li>Chosen credential must not be expired</li>
                            <li>
                              Document should be in good condition and clearly
                              visible
                            </li>
                            <li>
                              Make sure that there is no light glare on the
                              document
                            </li>
                          </ul>
                          <div className="gaps-2x" />
                          {passportSelected && (
                            <h5 className="font-mid mb-2">
                              Upload Here Your Passport Copy
                            </h5>
                          )}
                          {nationalIdSelected && (
                            <h5 className="font-mid mb-2">
                              Upload Here Your National ID Copy
                            </h5>
                          )}
                          {licenseSelected && (
                            <h5 className="font-mid mb-2">
                              Upload Here Your License Copy
                            </h5>
                          )}
                          <div className="row align-items-center">
                            <div className="col-sm-8">
                              <div
                                className="upload-box"
                                onDragEnter={handleDrag}
                              >
                                <div className="upload-zone dropzone dz-clickable">
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    className="hidden"
                                    onChange={handleChange}
                                    accept="image/*"
                                  />
                                  {fileUploaded ? (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text mb-3 text-base">
                                          {`${selectedFile?.name.substring(
                                            0,
                                            20
                                          )}...`}
                                        </span>
                                        <button
                                          className="btn btn-primary bg-[#2c80ff]"
                                          type="button"
                                          onClick={cancelUpload}
                                        >
                                          CANCEL
                                        </button>
                                      </label>
                                    </div>
                                  ) : (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text">
                                          Drag and drop file
                                        </span>
                                        <span className="dz-message-or">
                                          or
                                        </span>
                                        <button
                                          className="btn btn-primary bg-green"
                                          type="button"
                                          onClick={onButtonClick}
                                        >
                                          SELECT
                                        </button>
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {dragActive && (
                                  <div
                                    className="absolute w-full h-full border-[1rem] top-0 bottom-0 right-0 left-0"
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  ></div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 d-none d-sm-block">
                              <div className="mx-md-4">
                                <img src={vectorPassport} alt="vector" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="pay-notes"
                        style={{ border: "none", padding: "0" }}
                      >
                        <div className="note note-plane note-light note-md font-italic">
                          <em className="fas fa-info-circle" />
                          <p>Uploaded file should be 4MB or less.</p>
                        </div>
                      </div>
                      {noSelectedFile && (
                        <p className="mt-3 text-red text-sm">
                          Please upload a document
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-step form-step-final">
                    <div className="form-step-fields card-innr">
                      <div className="input-item">
                        <input
                          className="input-checkbox input-checkbox-md"
                          id="term-condition"
                          type="checkbox"
                          value={read}
                          onChange={toggleReadBox}
                        />
                        <label htmlFor="term-condition">
                          I have read the{" "}
                          <Link to="/terms-and-conditions">
                            <strong>Terms and Conditions</strong>
                          </Link>{" "}
                          and
                          <Link to="/privacy-policy">
                            <strong> Privacy Policy.</strong>
                          </Link>
                        </label>
                        {readUnchecked && (
                          <p className="mt-3 text-red text-sm">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="input-item">
                        <input
                          className="input-checkbox input-checkbox-md"
                          id="info-currect"
                          type="checkbox"
                          value={personal}
                          onChange={togglePersonalBox}
                        />
                        <label htmlFor="info-currect">
                          All the personal information I have entered is
                          correct.
                        </label>
                        {personalUnchecked && (
                          <p className="mt-3 text-red text-sm">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="gaps-1x" />
                      {loading ? (
                        <button className="btn btn-primary" disabled>
                          Submitting...
                        </button>
                      ) : (
                        <button className="btn btn-primary">Submit</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
      <ProfileFooter />
    </div>
  );
};

export default KycForm;
