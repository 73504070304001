import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <main className="mt-[78.22px] bg-white">
        <div className="text-center text-white bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h1 className="text-3xl md:text-4xl font-light text-white">
            Privacy Policy
          </h1>
        </div>

        <div className="mt-8 px-5 md:py-5 md:px-7 lg:px-24 xl:px-52 text-base">
          <p className="font-tinos text-gray mt-2">
            This privacy notice is effective August 11, 2017.
          </p>
          <p className="font-tinos text-gray mt-4">
            NextEra Energy, Inc. (NextEra Energy) is committed to protecting the
            personal information shared with us by new and existing customers,
            web site visitors, suppliers and other third parties, including
            vendors and potential vendors. Your privacy is a top priority for
            NextEra Energy and we make every reasonable effort to protect the
            information we hold about you. This privacy notice describes the
            personal information we collect, how we use the information, and how
            we protect it.
          </p>
          <p className="font-tinos text-gray mt-4">
            This notice applies to NextEra Energy and certain of our subsidiary
            companies. We will refer to these organizations as “NextEra Energy,”
            “we,” “us” or “our” throughout this document; including Florida
            Power &amp; Light Company (FPL). FPL is a subsidiary of NextEra, a
            rate-regulated electric utility in the state of Florida. Some of our
            subsidiaries, including Gexa Energy and its NextEra Energy Services
            affiliates, that provide retail electricity and related products and
            services outside of Florida are governed by privacy policies
            displayed at their websites that include different provisions
            applicable to those businesses and their customers.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Information we collect and how we use it
          </h2>
          <p className="font-tinos text-gray mt-2">
            NextEra Energy collects information to establish and provide
            services to our customers. We do not collect personal information
            about you through our websites or other digital properties unless
            you voluntarily provide it.&nbsp; Examples of the information we
            collect include:
          </p>
          <ul className="font-tinos text-gray mt-2 list-disc list-inside">
            <li>
              Personal identifying information like name, date of birth, address
              and government issued ID numbers
            </li>
            <li>Financial and banking information</li>
            <li>Contact information like phone numbers and email addresses</li>
            <li>Identifying information of third parties</li>
          </ul>
          <p className="font-tinos text-gray mt-4">
            We use information we collect to identify you; to establish,
            maintain and service your account; to communicate with you; to
            facilitate payments; and to operate, maintain and improve our
            business and the products and services we provide to our customers.
          </p>
          <p className="font-tinos text-gray mt-4">
            For suppliers, vendors and potential vendors, we collect information
            we require, including personal information, to consider your
            application to become a supplier or vendor. We may also use this
            information to process payments; to comply with any government
            reporting requirements; and for other internal business purposes.
          </p>
          <p className="font-tinos text-gray mt-4">
            When visiting our website or other digital properties, we collect
            information about how you use the website or digital properties.
            This includes the pages you visit as well as information about the
            device you are using. This information is provided automatically to
            us by your browser or by using cookies. We collect this information
            to improve your browsing experience and to address any technical
            problems which may occur.
          </p>
          <p className="font-tinos text-gray mt-4">
            On some occasions when visiting the NextEra Energy’s website or
            other digital properties, you may be asked to complete either an
            online survey or questionnaire. These surveys and questionnaires ask
            for your opinions, attitudes, interests, and more generally, about
            your activities online. The answers provided are used to improve the
            services offered through our digital properties.
          </p>
          <p className="font-tinos text-gray mt-4">
            <strong>
              The NextEra Energy website and other digital properties do not
              knowingly collect personal information from users under age 13 and
              are in no way directed at children under the age of 13. If you are
              under the age of 13, please do not submit a request for
              information, inquiry, or any personal information (including, but
              not limited to, your name, address, telephone number, e-mail
              address or any screen name or user name you may use) to us. If we
              learn that information has been submitted, or that we have
              collected personal information, from a user under the age of 13,
              we will take immediate steps to delete such information.
            </strong>
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Sharing of personal information
          </h2>
          <p className="font-tinos text-gray mt-4">
            In the normal course of business NextEra Energy shares personal
            information with third parties. We do this in the following, limited
            circumstances:
          </p>
          <ul className="font-tinos text-gray mt-2 list-disc list-inside">
            <li>
              Personal information is provided to our subsidiaries, affiliated
              companies or other third parties who assist us in providing you
              with current and potentially future services, or with other
              permitted uses of personal information (subject to appropriate
              confidentiality agreements and our instructions consistent with
              this privacy notice).
            </li>
            <li>
              Personal information is provided to: (a) satisfy applicable law,
              regulation, legal process or enforceable governmental request, (b)
              enforce applicable Terms of Service, including investigation of
              potential violations thereof, (c) detect, prevent or otherwise
              address fraud, security or technical issues, or (d) protect
              against imminent harm to the rights, property or safety of NextEra
              Energy, its customers or the public as required or permitted by
              law.
            </li>
            <li>
              We may disclose personal information with the U.S. Department of
              Energy, North American Electric Reliability Corporation, the
              Federal Energy Regulatory Commission, and their designees to
              participate in the protection of the security of critical
              infrastructure and key resources and ensuring information
              security. The information may be shared with law enforcement
              authorities in accordance with applicable law both in the United
              States and the originating country.
            </li>
            <li>
              We may disclose and/or transfer personal information to a third
              party in connection with a merger, acquisition, or sale (including
              any transfers made as part of insolvency or bankruptcy
              proceedings) involving NextEra Energy or its affiliated companies
              or as part of a corporate reorganization, stock or asset sale, or
              other change in corporate control.
            </li>
          </ul>
          <p className="font-tinos text-gray mt-4">
            We may also share with third parties certain pieces of aggregated,
            non–personal information, such as how many customers viewed a web
            page. Such information would not identify you individually.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Third party links on our website
          </h2>
          <p className="font-tinos text-gray mt-4">
            While browsing NextEra Energy website, you may encounter and choose
            to access websites or online services operated by companies or
            agencies that may not be associated with NextEra Energy. These
            websites may send their own cookies to you, log your IP address,
            and/or otherwise collect data or Personal Information about you and
            your online activities. NextEra Energy does not control and is not
            responsible for what outside entities do in connection with their
            websites or online services, or how they handle your Personal
            Information. Please use caution and consult the privacy policies
            posted on each outside website that you visit for further
            information.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Accessing and updating your personal information
          </h2>
          <p className="font-tinos text-gray mt-4">
            If you want to review and/or update the personal information you
            have provided to us, you may do so by logging in to your account or
            using the Customer Service section.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Website “Do Not Track” requests
          </h2>
          <p className="font-tinos text-gray mt-4">
            The NextEra Energy website does not respond to "Do Not Track"
            signals or other similar mechanisms. "Do Not Track" signals are used
            by some web browsers to attempt to limit tracking related to your
            visits to a website.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Protecting your personal information
          </h2>
          <p className="font-tinos text-gray mt-4">
            We use industry-standard administrative, technical and physical
            safeguards and protection practices and controls to ensure the
            integrity of our systems and to protect your information from loss,
            misuse, alteration and destruction. Such protection measures include
            firewalls and Secure Socket Layers (SSL) to protect and encrypt
            sensitive transmissions. Please be advised, however, that no
            security system is fail-safe and we cannot guarantee the information
            you provide will never be obtained by unauthorized persons.
          </p>
          <p className="font-tinos text-gray mt-4">
            We may contact you from time to time to provide you with useful
            information, updates regarding our services, and special offers;
            however, we do not and will not at any time request any of the
            following information via email or through any telephone call
            initiated by us:
          </p>
          <ul className="font-tinos text-gray mt-2 list-disc list-inside">
            <li>your account user ID</li>
            <li>your account password</li>
            <li>your social security number</li>
            <li>your credit card and/or bank information</li>
          </ul>
          <p className="font-tinos text-gray mt-4">
            Likewise, we will never send you an unsolicited email containing a
            link to the log-in page for our website.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Privacy Notice Updates
          </h2>
          <p className="font-tinos text-gray mt-4">
            We may change or update this privacy notice at any time. We will
            change the effective date found at the top of this privacy notice
            when an update is made. We recommend that you visit this web page
            periodically to be kept informed regarding any changes.
          </p>
          <p className="font-tinos text-gray mt-4">
            We may include a notification on our websites home pages to notify
            you of a privacy notice update.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            State Law
          </h2>
          <p className="font-tinos text-gray mt-4">
            You may have privacy rights under applicable state law in addition
            to those outlined in this Privacy Policy.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider font-sans text-black">
            Contact Us
          </h2>
          <p className="font-tinos text-gray mt-4 pb-8">
            If you have any questions about NextEra Energy’s privacy policies,
            please contact the Customer Service section.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
