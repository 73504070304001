import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { browserName, osName } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState();
  const [browser, setBrowser] = useState();
  const [os, setOs] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("authToken-nextera")) {
      navigate("/profile");
    }
  }, [navigate]);

  useEffect(() => {
    setBrowser(browserName);
    setOs(osName);

    const getData = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
    };

    getData();
  }, []);

  const showCredError = () => {
    toast.error("Invalid Credentials", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showVerifiedError = () => {
    toast.error("User not verified", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRestrictedError = () => {
    toast.error("Account restricted. Please contact support", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://nextera-api-new.onrender.com/api/auth/login",
        { email, password },
        config
      );

      await axios.post(
        "https://nextera-api-new.onrender.com/api/add-activity",
        {
          ip,
          browser,
          os,
          userId: data.userId,
        }
      );

      localStorage.setItem("authToken-nextera", data.token);
      setLoading(false);
      navigate("/profile");
    } catch (error) {
      if (error.response.data.error === "Invalid credentials") {
        setLoading(false);
        showCredError();
        return;
      }

      if (error.response.data.error === "User not verified!") {
        setLoading(false);
        showVerifiedError();
        return;
      }

      if (error.response.data.error === "Account restricted") {
        setLoading(false);
        showRestrictedError();
        return;
      }

      setLoading(false);
      showError();
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Sign In <small>to your Brokerage Account</small>
            </h2>
            <form onSubmit={loginHandler}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Email address"
                  className="input-bordered"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                    setEmailFieldEmpty(false);
                  }}
                />
                {emailFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    Please enter a valid email address
                  </p>
                )}
              </div>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="Password"
                  className="input-bordered"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordFieldEmpty(false);
                  }}
                />
                {passwordFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="input-item text-left">
                  {/* <input
                    className="input-checkbox input-checkbox-md"
                    id="remember-me"
                    type="checkbox"
                  />
                  <label htmlFor="remember-me">Remember Me</label> */}
                </div>

                <div>
                  <Link to="/forgot-password">Forgot password?</Link>
                  <div className="gaps-2x"></div>
                </div>
              </div>

              <ToastContainer />
              {loading ? (
                <button className="btn btn-primary btn-block" disabled>
                  Signing In...
                </button>
              ) : (
                <button className="btn btn-primary btn-block">Sign In</button>
              )}
            </form>

            <div className="gaps-2x"></div>
            <div className="form-note">
              Login may take a few seconds. Do not refresh the app while it
              loads.
            </div>

            <div className="gaps-2x"></div>
            <div className="gaps-2x"></div>
            <div className="form-note">
              Don’t have an account?
              <Link to="/register">
                <strong> Sign Up</strong>
              </Link>
            </div>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
              <li>
                &copy; {new Date(Date.now()).getFullYear()} NextEra Energy.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src={logo} alt="bgimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
