import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";

const User = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [className, setClassName] = useState("");
  const [personalSelected, setPersonalSelected] = useState(true);
  const [settingsSelected, setSettingsSelected] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
  const [numberFieldEmpty, setNumberFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [saveLog, setSaveLog] = useState(true);
  const [notify, setNotify] = useState(true);
  const [alert, setAlert] = useState(true);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [fieldEdited, setFieldEdited] = useState(false);
  const [ssn, setSsn] = useState("");

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const handleInput = (e) => {
    // this is where we'll call the formatSSN function
    const formattedSSN = formatSSN(e.target.value);
    // we'll set the input value using our setInputValue
    setSsn(formattedSSN);
  };

  function formatSSN(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const ssn = value.replace(/[^\d]/g, "");

    // ssnLength is used to know when to apply our formatting for the ssn
    const ssnLength = ssn.length;

    // we need to return the value with no formatting if its less then four digits
    if (ssnLength < 4) return ssn;

    // if ssnLength is greater than 4 and less the 6 we start to return
    // the formatted number
    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }

    // finally, if the ssnLength is greater then 6, we add the last
    // bit of formatting and return it.
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
  }

  useEffect(() => {
    setClassName("active");
  }, []);

  const selectPersonal = () => {
    setPersonalSelected(true);
    setSettingsSelected(false);
  };

  const selectSettings = () => {
    setSettingsSelected(true);
    setPersonalSelected(false);
  };

  const toggleSaveLog = async () => {
    await axios
      .put("https://nextera-api-new.onrender.com/api/save-log", {
        saveLog,
        userId: user?._id,
      })
      .then(() => {
        setSettingsChanged(true);

        setTimeout(() => {
          setSettingsChanged(false);
        }, 3000);
      });
  };

  const toggleNotify = async () => {
    await axios
      .put("https://nextera-api-new.onrender.com/api/notify", {
        notify,
        userId: user?._id,
      })
      .then(() => {
        setSettingsChanged(true);

        setTimeout(() => {
          setSettingsChanged(false);
        }, 3000);
      });
  };

  const toggleAlert = async () => {
    await axios
      .put("https://nextera-api-new.onrender.com/api/alert", {
        alert,
        userId: user?._id,
      })
      .then(() => {
        setSettingsChanged(true);

        setTimeout(() => {
          setSettingsChanged(false);
        }, 3000);
      });
  };

  const editUser = async (e) => {
    e.preventDefault();

    if (!fullName) {
      setNameFieldEmpty(true);
      return;
    } else {
      setNameFieldEmpty(false);
    }

    if (!number) {
      setNumberFieldEmpty(true);
      return;
    } else {
      setNumberFieldEmpty(false);
    }

    try {
      setLoading(true);

      await axios
        .put("https://nextera-api-new.onrender.com/api/edit-user", {
          fullName,
          number,
          ssn,
          userId: user?._id,
        })
        .then(() => {
          setLoading(false);
          setChangesSaved(true);
          setFieldEdited(false);

          setTimeout(() => {
            setChangesSaved(false);
          }, 3000);
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);
        setFullName(data.name);
        setNumber(data.number);
        setSsn(data.ssn);
        setSaveLog(data.saveLog);
        setNotify(data.notify);
        setAlert(data.alert);

        setPageLoading(false);
        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row">
            <div className="main-content col-lg-8">
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Profile Details</h4>
                  </div>
                  <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item">
                      <button
                        onClick={selectPersonal}
                        className={
                          personalSelected ? "nav-link active" : "nav-link"
                        }
                      >
                        Personal Data
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        onClick={selectSettings}
                        className={
                          settingsSelected ? "nav-link active" : "nav-link"
                        }
                      >
                        Settings
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="profile-details">
                    {personalSelected && (
                      <div
                        className="tab-pane fade show active"
                        id="personal-data"
                      >
                        <form onSubmit={editUser}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="full-name"
                                  className="input-item-label"
                                >
                                  Full Name
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="full-name"
                                  name="full-name"
                                  defaultValue={fullName}
                                  onChange={(e) => {
                                    setFullName(e.target.value);
                                    setNameFieldEmpty(false);
                                    setFieldEdited(true);
                                  }}
                                />
                                {nameFieldEmpty && (
                                  <p className="mt-3 text-red text-sm">
                                    This field is required
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="email-address"
                                  className="input-item-label"
                                >
                                  Email Address
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="email-address"
                                  name="email-address"
                                  defaultValue={user?.email}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="mobile-number"
                                  className="input-item-label"
                                >
                                  Mobile Number
                                </label>
                                <PhoneInput
                                  className="input-bordered outline-0"
                                  value={number}
                                  onChange={(number) => {
                                    setNumber(number);
                                    setNameFieldEmpty(false);
                                    setFieldEdited(true);
                                  }}
                                />
                                {numberFieldEmpty && (
                                  <p className="mt-3 text-red text-sm">
                                    This field is required
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="ssn"
                                  className="input-item-label"
                                >
                                  SSN
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="ssn"
                                  maxLength={11}
                                  value={ssn}
                                  onChange={(e) => {
                                    handleInput(e);
                                    setFieldEdited(true);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="gaps-1x" />
                          <div className="d-sm-flex justify-content-between align-items-center">
                            {loading && (
                              <button className="btn btn-primary" disabled>
                                Updating...
                              </button>
                            )}
                            {!fieldEdited && (
                              <button className="btn btn-primary" disabled>
                                Update Profile
                              </button>
                            )}
                            {fieldEdited && !loading && (
                              <button className="btn btn-primary">
                                Update Profile
                              </button>
                            )}
                            <div className="gaps-2x d-sm-none" />
                            {changesSaved && (
                              <span className="text-success">
                                <em className="ti ti-check-box" /> All Changes
                                are saved
                              </span>
                            )}
                          </div>
                        </form>
                      </div>
                    )}
                    {settingsSelected && (
                      <div className="tab-pane fade show active" id="settings">
                        <div className="pdb-1-5x">
                          <h5 className="card-title card-title-sm text-dark">
                            Security Settings
                          </h5>
                        </div>
                        <div className="input-item">
                          <input
                            type="checkbox"
                            className="input-switch input-switch-sm"
                            id="save-log"
                            checked={saveLog}
                            onChange={() => {
                              setSaveLog((prevState) => !prevState);
                              toggleSaveLog();
                            }}
                          />
                          <label htmlFor="save-log">
                            Save my Activities Log
                          </label>
                        </div>

                        <div className="pdb-1-5x">
                          <h5 className="card-title card-title-sm text-dark">
                            Manage Notifications
                          </h5>
                        </div>
                        <div className="input-item">
                          <input
                            type="checkbox"
                            className="input-switch input-switch-sm"
                            id="latest-news"
                            checked={notify}
                            onChange={() => {
                              setNotify((prevState) => !prevState);
                              toggleNotify();
                            }}
                          />
                          <label htmlFor="latest-news">
                            Notify me by email about sales and latest news
                          </label>
                        </div>
                        <div className="input-item">
                          <input
                            type="checkbox"
                            className="input-switch input-switch-sm"
                            id="activity-alert"
                            checked={alert}
                            onChange={() => {
                              setAlert((prevState) => !prevState);
                              toggleAlert();
                            }}
                          />
                          <label htmlFor="activity-alert">
                            Alert me by email for unusual activity.
                          </label>
                        </div>
                        <div className="gaps-1x" />
                        {settingsChanged && (
                          <div className="d-flex justify-content-between align-items-center">
                            <span />
                            <span className="text-success">
                              <em className="ti ti-check-box" /> Settings
                              updated
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="aside sidebar-right col-lg-4">
              <div className="account-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Your Account Status
                  </h6>
                  <ul className="btn-grp">
                    <li>
                      <span className="btn btn-auto btn-xs btn-success">
                        Email Verified
                      </span>
                    </li>
                    {user?.kycStatus === "Approved" ? (
                      <li>
                        <span className="btn btn-auto btn-xs btn-success">
                          KYC Verified
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span className="btn btn-auto btn-xs btn-warning">
                          KYC Pending
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
    </div>
  );
};

export default User;
