import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import Pagination from "../../components/Pagination";

const Transactions = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [className, setClassName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState(null);
  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [allSelected, setAllSelected] = useState(true);
  const [purchaseSelected, setPurchaseSelected] = useState(false);
  const [withdrawalSelected, setWithdrawalSelected] = useState(false);
  const [creditedSelected, setCreditedSelected] = useState(false);

  const navigate = useNavigate();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = transactions?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(transactions?.length / recordsPerPage);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const selectAll = () => {
    setAllSelected(true);
    setPurchaseSelected(false);
    setWithdrawalSelected(false);
    setCreditedSelected(false);
  };

  const selectPurchase = () => {
    setPurchaseSelected(true);
    setAllSelected(false);
    setWithdrawalSelected(false);
    setCreditedSelected(false);
  };

  const selectWithdrawal = () => {
    setWithdrawalSelected(true);
    setPurchaseSelected(false);
    setAllSelected(false);
    setCreditedSelected(false);
  };

  const selectCredited = () => {
    setCreditedSelected(true);
    setWithdrawalSelected(false);
    setPurchaseSelected(false);
    setAllSelected(false);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const deleteTransaction = async (transaction) => {
    setIdToBeDeleted(transaction._id);
    setDeleteModalOpen(true);
  };

  const confirmDeletion = async () => {
    setLoading(true);

    try {
      await axios.put(
        "https://nextera-api-new.onrender.com/api/delete-transaction",
        {
          idToBeDeleted,
        }
      );

      setLoading(false);
      setDeleteModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    setClassName("active");
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setUserLoaded(true);
        setBalance(data.balance);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    const getTransactions = async () => {
      if (userLoaded) {
        try {
          const { data } = await axios.post(
            "https://nextera-api-new.onrender.com/api/all-transactions",
            { userId: user?._id }
          );

          setTransactions(data);
          setPageLoading(false);
        } catch (error) {}
      }
    };

    getTransactions();
  }, [user?._id, userLoaded]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">User Transactions</h4>
              </div>
              <div>
                <div className="gaps-1x" />
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                  <li className="nav-item">
                    <button
                      className={allSelected ? "nav-link active" : "nav-link"}
                      onClick={selectAll}
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        purchaseSelected ? "nav-link active" : "nav-link"
                      }
                      onClick={selectPurchase}
                    >
                      Purchase
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        withdrawalSelected ? "nav-link active" : "nav-link"
                      }
                      onClick={selectWithdrawal}
                    >
                      Withdrawal
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        creditedSelected ? "nav-link active" : "nav-link"
                      }
                      onClick={selectCredited}
                    >
                      Credited
                    </button>
                  </li>
                </ul>
                <div className="gaps-1x" />
              </div>

              {allSelected && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Tranx NO</th>
                      <th className="data-col dt-token">Shares</th>
                      <th className="data-col dt-usd-amount">USD Amount</th>
                      <th className="data-col dt-account">Status</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords.map((transaction) => (
                      <tr className="data-item" key={transaction._id}>
                        <td className="data-col dt-tnxno">
                          <div className="d-flex align-items-center">
                            {transaction.status === "Pending" && (
                              <div className="data-state data-state-progress">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            {transaction.status === "Rejected" && (
                              <div className="data-state data-state-canceled">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            {transaction.status === "Confirmed" && (
                              <div className="data-state data-state-approved">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            <div className="fake-class">
                              <span className="lead tnx-id">
                                TNX{transaction.transactionId}
                              </span>
                              <span className="sub sub-date">
                                {new Date(
                                  transaction.createdAt
                                ).toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="data-col dt-token">
                          <span className="lead token-amount">
                            {transaction.shares}
                          </span>
                        </td>
                        <td className="data-col dt-amount">
                          <span className="lead amount-pay">
                            {transaction.usd.toLocaleString()}
                          </span>
                          <span className="sub sub-symbol">USD</span>
                        </td>
                        <td className="data-col dt-usd-amount">
                          <span className="lead amount-pay">
                            {transaction.status}
                          </span>
                        </td>
                        {transaction.type === "Deposit" && (
                          <td className="data-col dt-type">
                            <span className="dt-type-md badge badge-outline badge-success badge-md border-[blue]">
                              Purchase
                            </span>
                            <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[blue]">
                              P
                            </span>
                          </td>
                        )}
                        {transaction.type === "Withdrawal" && (
                          <td className="data-col dt-type">
                            <span className="dt-type-md badge badge-outline badge-success badge-md border-[orange]">
                              Withdrawal
                            </span>
                            <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[orange]">
                              W
                            </span>
                          </td>
                        )}
                        {transaction.type === "Credited" && (
                          <td className="data-col dt-type">
                            <span className="dt-type-md badge badge-outline badge-success badge-md">
                              Credited
                            </span>
                            <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                              C
                            </span>
                          </td>
                        )}
                        <td className="data-col text-right">
                          <div className="relative d-inline-block d-md-none">
                            <button
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                              onClick={() =>
                                setTransactionId((transactionId) =>
                                  transactionId === transaction._id
                                    ? null
                                    : transaction._id
                                )
                              }
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {transactionId === transaction._id && (
                              <div className="toggle-class dropdown-content dropdown-content-center-left pd-2x active">
                                <ul className="data-action-list">
                                  <li>
                                    <button className="btn btn-light-alt btn-xs btn-icon">
                                      <Link
                                        to={`/transactions/${transaction._id}`}
                                      >
                                        <em className="ti ti-eye" />
                                      </Link>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() =>
                                        deleteTransaction(transaction)
                                      }
                                      className="btn btn-danger-alt btn-xs btn-icon"
                                    >
                                      <em className="ti ti-trash" />
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <ul className="data-action-list d-none d-md-inline-flex">
                            <li>
                              <button className="btn btn-light-alt btn-xs btn-icon">
                                <Link to={`/transactions/${transaction._id}`}>
                                  <em className="ti ti-eye" />
                                </Link>
                              </button>
                            </li>
                            <li>
                              <button
                                className="btn btn-danger-alt btn-xs btn-icon"
                                onClick={() => deleteTransaction(transaction)}
                              >
                                <em className="ti ti-trash" />
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {allSelected && transactions?.length === 0 && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    No transactions yet
                  </p>
                </div>
              )}
              {allSelected && transactions?.length > 10 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
              {purchaseSelected && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Tranx NO</th>
                      <th className="data-col dt-token">Shares</th>
                      <th className="data-col dt-usd-amount">USD Amount</th>
                      <th className="data-col dt-account">Status</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      .filter((trnx) => trnx.type === "Deposit")
                      .map((transaction) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  TNX{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction.shares}
                            </span>
                          </td>
                          <td className="data-col dt-amount">
                            <span className="lead amount-pay">
                              {transaction.usd.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-usd-amount">
                            <span className="lead amount-pay">
                              {transaction.status}
                            </span>
                          </td>
                          {transaction.type === "Deposit" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md border-[blue]">
                                Purchase
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[blue]">
                                P
                              </span>
                            </td>
                          )}
                          {transaction.type === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md border-[orange]">
                                Withdrawal
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[orange]">
                                W
                              </span>
                            </td>
                          )}
                          {transaction.type === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <div className="relative d-inline-block d-md-none">
                              <button
                                className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                                onClick={() =>
                                  setTransactionId((transactionId) =>
                                    transactionId === transaction._id
                                      ? null
                                      : transaction._id
                                  )
                                }
                              >
                                <em className="ti ti-more-alt" />
                              </button>
                              {transactionId === transaction._id && (
                                <div className="toggle-class dropdown-content dropdown-content-center-left pd-2x active">
                                  <ul className="data-action-list">
                                    <li>
                                      <button className="btn btn-light-alt btn-xs btn-icon">
                                        <Link
                                          to={`/transactions/${transaction._id}`}
                                        >
                                          <em className="ti ti-eye" />
                                        </Link>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() =>
                                          deleteTransaction(transaction)
                                        }
                                        className="btn btn-danger-alt btn-xs btn-icon"
                                      >
                                        <em className="ti ti-trash" />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                            <ul className="data-action-list d-none d-md-inline-flex">
                              <li>
                                <button className="btn btn-light-alt btn-xs btn-icon">
                                  <Link to={`/transactions/${transaction._id}`}>
                                    <em className="ti ti-eye" />
                                  </Link>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn btn-danger-alt btn-xs btn-icon"
                                  onClick={() => deleteTransaction(transaction)}
                                >
                                  <em className="ti ti-trash" />
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {withdrawalSelected && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Tranx NO</th>
                      <th className="data-col dt-token">Shares</th>
                      <th className="data-col dt-usd-amount">USD Amount</th>
                      <th className="data-col dt-account">Status</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      .filter((trnx) => trnx.type === "Withdrawal")
                      .map((transaction) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  TNX{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction.shares}
                            </span>
                          </td>
                          <td className="data-col dt-amount">
                            <span className="lead amount-pay">
                              {transaction.usd.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-usd-amount">
                            <span className="lead amount-pay">
                              {transaction.status}
                            </span>
                          </td>
                          {transaction.type === "Deposit" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md border-[blue]">
                                Purchase
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[blue]">
                                P
                              </span>
                            </td>
                          )}
                          {transaction.type === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md border-[orange]">
                                Withdrawal
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[orange]">
                                W
                              </span>
                            </td>
                          )}
                          {transaction.type === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <div className="relative d-inline-block d-md-none">
                              <button
                                className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                                onClick={() =>
                                  setTransactionId((transactionId) =>
                                    transactionId === transaction._id
                                      ? null
                                      : transaction._id
                                  )
                                }
                              >
                                <em className="ti ti-more-alt" />
                              </button>
                              {transactionId === transaction._id && (
                                <div className="toggle-class dropdown-content dropdown-content-center-left pd-2x active">
                                  <ul className="data-action-list">
                                    <li>
                                      <button className="btn btn-light-alt btn-xs btn-icon">
                                        <Link
                                          to={`/transactions/${transaction._id}`}
                                        >
                                          <em className="ti ti-eye" />
                                        </Link>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() =>
                                          deleteTransaction(transaction)
                                        }
                                        className="btn btn-danger-alt btn-xs btn-icon"
                                      >
                                        <em className="ti ti-trash" />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                            <ul className="data-action-list d-none d-md-inline-flex">
                              <li>
                                <button className="btn btn-light-alt btn-xs btn-icon">
                                  <Link to={`/transactions/${transaction._id}`}>
                                    <em className="ti ti-eye" />
                                  </Link>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn btn-danger-alt btn-xs btn-icon"
                                  onClick={() => deleteTransaction(transaction)}
                                >
                                  <em className="ti ti-trash" />
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {creditedSelected && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Tranx NO</th>
                      <th className="data-col dt-token">Shares</th>
                      <th className="data-col dt-usd-amount">USD Amount</th>
                      <th className="data-col dt-account">Status</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      .filter((trnx) => trnx.type === "Credited")
                      .map((transaction) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  TNX{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction.shares}
                            </span>
                          </td>
                          <td className="data-col dt-amount">
                            <span className="lead amount-pay">
                              {transaction.usd.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-usd-amount">
                            <span className="lead amount-pay">
                              {transaction.status}
                            </span>
                          </td>
                          {transaction.type === "Deposit" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md border-[blue]">
                                Purchase
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[blue]">
                                P
                              </span>
                            </td>
                          )}
                          {transaction.type === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md border-[orange]">
                                Withdrawal
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md border-[orange]">
                                W
                              </span>
                            </td>
                          )}
                          {transaction.type === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <div className="relative d-inline-block d-md-none">
                              <button
                                className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                                onClick={() =>
                                  setTransactionId((transactionId) =>
                                    transactionId === transaction._id
                                      ? null
                                      : transaction._id
                                  )
                                }
                              >
                                <em className="ti ti-more-alt" />
                              </button>
                              {transactionId === transaction._id && (
                                <div className="toggle-class dropdown-content dropdown-content-center-left pd-2x active">
                                  <ul className="data-action-list">
                                    <li>
                                      <button className="btn btn-light-alt btn-xs btn-icon">
                                        <Link
                                          to={`/transactions/${transaction._id}`}
                                        >
                                          <em className="ti ti-eye" />
                                        </Link>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() =>
                                          deleteTransaction(transaction)
                                        }
                                        className="btn btn-danger-alt btn-xs btn-icon"
                                      >
                                        <em className="ti ti-trash" />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                            <ul className="data-action-list d-none d-md-inline-flex">
                              <li>
                                <button className="btn btn-light-alt btn-xs btn-icon">
                                  <Link to={`/transactions/${transaction._id}`}>
                                    <em className="ti ti-eye" />
                                  </Link>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn btn-danger-alt btn-xs btn-icon"
                                  onClick={() => deleteTransaction(transaction)}
                                >
                                  <em className="ti ti-trash" />
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {deleteModalOpen && <div className="modal-backdrop fade show" />}
      {deleteModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setDeleteModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to delete this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Deleting...
                  </button>
                ) : (
                  <button onClick={confirmDeletion} className="btn btn-primary">
                    Yes, Delete
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Transactions;
