import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";

const AdminPayment = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState();
  const [accountType, setAccountType] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [routingNumber, setRoutingNumber] = useState();
  const [detailsChanged, setDetailsChanged] = useState(false);
  const [pinModalOpen, setPinModalOpen] = useState(true);
  const [pin, setPin] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      setFileUploaded(true);
    } else {
      setSelectedFile(null);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setFileUploaded(true);
    } else {
      setSelectedFile(null);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const cancelUpload = () => {
    setFileUploaded(false);
    setSelectedFile(null);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const editDetails = async () => {
    setLoading(true);

    if (selectedFile) {
      const fileName = new Date().getTime() + selectedFile.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          showError();
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const editDetails = async () => {
              await axios.put(
                "https://nextera-api-new.onrender.com/api/details",
                {
                  accountName,
                  accountNumber,
                  accountType,
                  bankAddress,
                  bankName,
                  cryptoAddress,
                  qr: downloadURL,
                  routingNumber,
                }
              );
            };

            editDetails();
            setLoading(false);
            setDetailsChanged(true);
            setConfirmModalOpen(false);
            window.location.reload();
          });
        }
      );
    } else {
      try {
        await axios.put("https://nextera-api-new.onrender.com/api/details", {
          accountName,
          accountNumber,
          accountType,
          bankAddress,
          bankName,
          cryptoAddress,
          routingNumber,
        });

        setLoading(false);
        setDetailsChanged(true);
        setConfirmModalOpen(false);
        window.location.reload();
      } catch (error) {
        showError();
        setLoading(false);
      }
    }
  };

  const pinConfirm = async () => {
    const { data } = await axios.post(
      "https://nextera-api-new.onrender.com/api/confirm-pd",
      {
        pin,
      }
    );

    if (data === "Confirmed") {
      setPinModalOpen(false);
    } else {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);
        setPageLoading(false);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    try {
      const getPaymentDetails = async () => {
        const { data } = await axios.get(
          "https://nextera-api-new.onrender.com/api/get-details"
        );

        setAccountName(data.accountName);
        setAccountNumber(data.accountNumber);
        setAccountType(data.accountType);
        setBankAddress(data.bankAddress);
        setBankName(data.bankName);
        setRoutingNumber(data.routingNumber);
        setCryptoAddress(data.cryptoAddress);
      };

      getPaymentDetails();
    } catch (error) {}
  }, []);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      {!pinModalOpen && (
        <div className="page-content">
          <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="row">
              <div className="main-content" style={{ width: "100%" }}>
                <div className="content-area card">
                  <div className="card-innr">
                    <div className="card-head">
                      <h4 className="card-title">Payment Details</h4>
                    </div>
                    <div className="tab-content" id="profile-details">
                      <div
                        className="tab-pane fade show active"
                        id="personal-data"
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            setConfirmModalOpen(true);
                          }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="account-name"
                                  className="input-item-label"
                                >
                                  Account Name
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="account-name"
                                  defaultValue={accountName}
                                  onChange={(e) =>
                                    setAccountName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="account-number"
                                  className="input-item-label"
                                >
                                  Account Number
                                </label>
                                <input
                                  className="input-bordered"
                                  type="number"
                                  id="account-number"
                                  defaultValue={accountNumber}
                                  onChange={(e) =>
                                    setAccountNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="account-name"
                                  className="input-item-label"
                                >
                                  Account Type
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="account-name"
                                  defaultValue={accountType}
                                  onChange={(e) =>
                                    setAccountType(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="account-name"
                                  className="input-item-label"
                                >
                                  Bank Address
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="account-name"
                                  defaultValue={bankAddress}
                                  onChange={(e) =>
                                    setBankAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="bank-name"
                                  className="input-item-label"
                                >
                                  Bank Name
                                </label>
                                <input
                                  className="input-bordered"
                                  type="text"
                                  id="bank-name"
                                  defaultValue={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="routing-number"
                                  className="input-item-label"
                                >
                                  Routing Number
                                </label>
                                <input
                                  className="input-bordered date-picker-dob"
                                  type="text"
                                  id="routing-number"
                                  defaultValue={routingNumber}
                                  onChange={(e) =>
                                    setRoutingNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-item input-with-label">
                                <label
                                  htmlFor="crypto-address"
                                  className="input-item-label"
                                >
                                  Crypto Address
                                </label>
                                <input
                                  className="input-bordered date-picker-dob"
                                  type="text"
                                  id="crypto-address"
                                  defaultValue={cryptoAddress}
                                  onChange={(e) =>
                                    setCryptoAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mt-4">
                            <div className="col-sm-8">
                              <div
                                className="upload-box"
                                onDragEnter={handleDrag}
                              >
                                <div className="upload-zone dropzone dz-clickable">
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    className="hidden"
                                    onChange={handleChange}
                                    accept="image/*"
                                  />
                                  {fileUploaded ? (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span
                                          className="dz-message-text mb-3 text-lg"
                                          style={{ color: "#007db6" }}
                                        >
                                          {selectedFile.name.length > 20
                                            ? `${selectedFile?.name.substring(
                                                0,
                                                20
                                              )}...`
                                            : selectedFile?.name}
                                        </span>
                                        <button
                                          className="btn btn-primary bg-green"
                                          type="button"
                                          onClick={cancelUpload}
                                        >
                                          CANCEL
                                        </button>
                                      </label>
                                    </div>
                                  ) : (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text">
                                          Drag and drop file
                                        </span>
                                        <span className="dz-message-or">
                                          or
                                        </span>
                                        <button
                                          className="btn btn-primary bg-green"
                                          type="button"
                                          onClick={onButtonClick}
                                        >
                                          SELECT
                                        </button>
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {dragActive && (
                                  <div
                                    className="absolute w-full h-full border-[1rem] top-0 bottom-0 right-0 left-0"
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  ></div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="gaps-1x" />
                          <div className="d-sm-flex justify-content-between align-items-center">
                            <button className="btn btn-primary">Update</button>
                            <div className="gaps-2x d-sm-none" />
                            {detailsChanged && (
                              <span className="text-success">
                                <em className="ti ti-check-box" /> All Changes
                                are saved
                              </span>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {confirmModalOpen && <div className="modal-backdrop fade show" />}
      {confirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setConfirmModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Are you sure?</h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button
                    onClick={editDetails}
                    className="btn btn-primary bg-green"
                    type="button"
                  >
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {pinModalOpen && <div className="modal-backdrop fade show" />}
      {pinModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Input Pin</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="password"
                  value={pin}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
                <button onClick={pinConfirm} className="btn btn-primary">
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPayment;
