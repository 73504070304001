import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TermsConditions = () => {
  return (
    <>
      <Header />
      <main className="mt-[78.22px] bg-white">
        <div className="text-center bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h1 className="text-3xl md:text-4xl font-light text-white">
            Terms & Conditions
          </h1>
        </div>

        <div className="mt-8 px-5 md:py-5 md:px-7 lg:px-24 xl:px-52">
          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            WEB SITE TERMS AND CONDITIONS
          </h2>
          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans mb-4">
            INTRODUCTION
          </h2>
          <p className="font-tinos mt-2 text-base text-gray">
            Welcome, we would like to take this opportunity to thank you for
            visiting the NextEra Energy, Inc. website.
          </p>
          <p className="font-tinos mt-4 text-base text-gray">
            The trademarks, service marks, trade names, logos and other
            indications of origin displayed within the NextEra Energy Site are
            owned by NextEra Energy or a third party who has granted NextEra
            Energy a right to use them.
          </p>
          <p className="font-tinos mt-4 text-base text-gray">
            By using our NextEra Energy Site, you represent that you agree with
            our Terms of Use and Privacy Policy (our “Privacy Policy”) found
            elsewhere on the NextEra Energy Site. If you do not agree with
            anything found in either these Terms of Use or our Privacy Policy,
            please do not use the NextEra Energy Site.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            ACKNOWLEDGEMENT
          </h2>
          <p className="font-tinos mt-2 text-base text-gray">
            You acknowledge that these Terms of Use are supported by reasonable
            and valuable consideration, the receipt of which is hereby
            acknowledged. Without limiting the foregoing, you acknowledge that
            such consideration includes without limitation, your use of the
            NextEra Energy Site, receipt of data, access to materials and
            information made available through the use of NextEra Energy Site,
            the possibility of our review, use or display of your Submissions
            (as defined below in the section labeled “Submissions To Nextera
            Energy Site”), and the possibility of publicity and promotion from
            our review, use or display of your Submissions.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            REGISTRATION AND SITE SECURITY
          </h2>
          <p className="font-tinos mt-2 text-base text-gray">
            NextEra Energy uses industry standard encryption technologies when
            transferring and receiving consumer data exchanged with the NextEra
            Energy Site. NextEra Energy service request forms use Secure Sockets
            Layer encryption technology during data transmission in an effort to
            protect your communication and maintain your privacy.
          </p>
          <p className="font-tinos mt-4 text-base text-gray">
            NextEra Energy does not knowingly reveal any personal information
            that is provided by our customers through the NextEra Energy Site to
            any third parties, including between direct and indirect
            wholly-owned subsidiaries of the NextEra Energy, except when
            requested to do so by a governmental agency having jurisdiction, or
            by a court of competent jurisdiction or other operation of law. We
            endeavor to keep all information provided by customers using our
            online forms strictly confidential and secure in an effort to
            prevent unauthorized use of personal information.
          </p>
          <p className="font-tinos mt-4 text-base text-gray">
            By using the NextEra Energy Site you agree to provide and maintain
            on our records, current and accurate personal information as
            indicated to you during our registration processes. If you provide
            any information which is inaccurate or outdated, or if we have
            reasonable grounds to question the truthfulness of the information
            provided, we reserve the right to suspend or terminate your account
            and refuse any and all current or future use of the NextEra Energy
            Site.
          </p>
          <p className="font-tinos mt-4 text-base text-gray">
            YOU ACKNOWLEDGE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY AND ALL
            LOSSES AND DAMAGES RESULTING FROM YOUR FAILURE TO COMPLY WITH THIS
            SECTION, AND THAT WE EXPRESSLY DISCLAIM ALL LIABILITY FOR SUCH
            LOSSES OR DAMAGES.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            RESTRICTIONS OF USE
          </h2>
          <p className="font-tinos mt-2 text-base text-gray">
            All information, content and materials contained on the NextEra
            Energy Site is the property of NextEra Energy or its licensors and
            is protected under U.S. and international copyright law. None of the
            information, content or material from any NextEra Energy Site or any
            internet site owned, operated, licensed, or controlled by us may be
            copied, reproduced, republished, uploaded, posted, transmitted or
            distributed in any way without our express written permission. The
            use of any information, content or materials on a NextEra Energy
            Site on any other web site or computer environment without our
            express written permission (which may be withheld for any reason) is
            strictly prohibited.
          </p>
          <p className="font-tinos mt-4 text-base text-gray">
            The NextEra Energy Site is for your personal use only. You may not
            use the NextEra Energy Site for commercial purposes or in any way
            that is unlawful or harms us or any other person or entity, as
            determined in our sole discretion.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            NEXTERA ENERGY SITE RULES
          </h2>
          <p className="font-tinos mt-2 text-base text-gray">
            The following is a list of rules you MUST follow in order to use
            NextEra Energy Sites. By using any of the NextEra Energy Sites, you
            represent the following:
          </p>
          <ul className="font-tinos text-gray mt-2 list-disc list-inside text-base">
            <li>
              You will not submit or transmit any information or material that:
              (a) is defamatory, abusive, harassing, insulting, threatening, or
              that could be deemed to be an invasion of another’s right to
              privacy; (b) is offensive; (c) is violent, vulgar, obscene,
              pornographic or otherwise sexually explicit; (d) is illegal or
              encourages or advocates illegal activity or the discussion of
              illegal activities with the intent to commit them; (e) infringes
              or violates any right of a third party or any domestic or
              international law, rule or regulation including, but not limited
              to: (i) copyright, patent, trademark, trade secret or other
              proprietary or contractual rights; (ii) right of privacy
              (specifically, you must not distribute another person's personal
              information of any kind without their express permission) or
              publicity; or (iii) any confidentiality obligation; or (f) does
              not comply with all terms and conditions applicable to the
              Submissions License.
            </li>
            <li className="mt-2">
              You own or have the necessary rights, licenses, consents and
              permissions, without the need for any permission from or payment
              to any other person or entity, to use and to authorize us to use,
              distribute or otherwise exploit in all manners permitted by these
              Terms of Use (including without limitation, the Submissions
              License), all copyrights, trademarks, patents, trade secrets,
              privacy and publicity rights (including without limitation, names,
              likenesses and voices) and/or other proprietary rights contained
              in any User Content that you submit, post, upload, distribute or
              otherwise make available or transmit.
            </li>
            <li className="mt-2">
              You will not submit or transmit any software or other computer
              files that contain a virus or other harmful component or otherwise
              impair or damage the NextEra Energy Site or any connected network
              or otherwise interfere with any person or entity's use or
              enjoyment of the NextEra Energy Site.
            </li>
            <li className="mt-2">
              You will not impersonate any person or entity or misrepresent your
              identity or affiliation with any person or entity including
              without limitation, using another person's username, password or
              other account information, or another person's name, likeness,
              voice, image or photograph.
            </li>
            <li className="mt-2">
              You will not delete any legal notices, disclaimers or proprietary
              notices such as copyright or trademark symbols or modify any logos
              that you do not own or have express permission to modify.
            </li>
            <li className="mt-2">
              You will not submit or transmit any information or communication
              that is in violation of these Terms of Use.
            </li>
            <li className="mt-2">
              You will not engage in (or attempt to engage in) any type of
              criminal activity within the NextEra Energy Site.
            </li>
          </ul>
          <p className="font-tinos mt-4 text-base text-gray">
            We cannot and do not assure that other users are or will be in
            compliance with the NextEra Energy Site rules or any other
            provisions of these Terms of Use, and, as between you and NextEra
            Energy, you hereby assume all risk of harm or injury resulting from
            any other person’s lack of compliance which causes you harm.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            To the extent that any feature on a NextEra Energy Site requires you
            to submit your e-mail address in order to send an e-mail or message,
            you must, and you hereby agree to, furnish your actual e-mail
            address, as applicable.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            Any business use, “re-mailing,” or other high-volume application is
            strictly prohibited. You are not permitted to send e-mails or
            messages using an automated program. E-mails and mobile messages may
            not contain inappropriate language or images or copyrighted material
            (without the consent of the copyright holder) or encourage illegal
            activity. We reserve the right not to deliver e-mails messages that
            violate these guidelines.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            <strong>
              WE RESERVE THE RIGHT TO MODIFY OR CHANGE THE NEXTERA ENERGY SITE
              INCLUDING WITHOUT LIMITATION ANY OF THE CONTENT, INFORMATION OR
              MATERIALS APPEARING ON OR AVAILABLE THROUGH THE NEXTERA ENERGY
              SITE FOR ANY REASON WHATSOEVER AND WITHOUT PRIOR NOTICE TO YOU.
            </strong>
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            SAFE HARBOR STATEMENT UNDER THE PRIVATE SECURITIES LITIGATION REFORM
            ACT OF 1995
          </h2>
          <p className="font-tinos text-gray mt-2 text-base">
            The statements contained in this web site which are not historical
            facts, may be deemed to contain forward-looking statements
            including, but not limited to deployment, scalability and
            integration of NextEra Energy in new and existing markets and the
            timing and breadth of coverage in each market. Actual results may
            differ materially from those anticipated in any forward-looking
            statements as a result of certain risks and uncertainties including,
            without limitation, dependence on strategic third parties to market
            and resell its services, intense competition for service offerings,
            dependence on growth in demand for electrical services, availability
            of electrical services space, ability to manage and scale
            operations, and other risks and uncertainties detailed in the
            Company's Securities and Exchange Commission filings. NextEra Energy
            disclaims any obligation to update information contained in any
            forward-looking statement.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            DISCLAIMER; LIMITATION OF LIABILITY
          </h2>
          <p className="font-tinos text-gray mt-2 text-base">
            NEXTERA ENERGY DOES NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULT
            THAT YOU MAY OBTAIN USING THE NEXTERA ENERGY SITE OR THE
            INFORMATION, CONTENT AND MATERIALS CONTAINED ON THE NEXTERA ENERGY
            SITE. THE INFORMATION, CONTENT AND MATERIALS ON NEXTERA ENERGY SITE
            IS PROVIDED ON AN "AS IS" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
            INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF MERCHANTABILITY
            OR FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE
            FUNCTIONS CONTAINED IN INFORMATION, CONTENT AND MATERIALS ON ANY
            NEXTERA ENERGY SITE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT THE
            NEXTERA ENERGY SITE OR THE SERVERS THAT MAKE SUCH INFORMATION,
            CONTENT AND MATERIALS AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. YOU ASSUME THE ENTIRE LIABILITY FOR THE SELECTION AND
            USE OF THE NEXTERA ENERGY SITE AND NEXTERA ENERGY SHALL HAVE NO
            LIABILITY FOR ANY ERRORS, MALFUNCTIONS, DEFECTS, OR LOSS OF DATA
            RESULTING FROM OR RELATED TO YOUR USE OF THE NEXTERA ENERGY SITE.
            YOU ACKNOWLEDGE THAT THIS DISCLAIMER SHALL SURVIVE TERMINATION OF
            THESE TERMS OF USE.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEXTERA
            ENERGY’S OWN NEGLIGENCE, SHALL NEXTERA ENERGY, OUR LICENSORS,
            LICENSEES, DISTRIBUTORS, AGENTS, REPRESENTATIVES AND OTHER
            AUTHORIZED USERS, AND EACH OF THE FOREGOING ENTITIES' RESPECTIVE
            RESELLERS, DISTRIBUTORS, SERVICE PROVIDERS AND SUPPLIERS, AND ALL OF
            THE FOREGOING ENTITIES’ RESPECTIVE OFFICERS, DIRECTORS, OWNERS,
            EMPLOYEES, AGENTS, REPRESENTATIVES AND ASSIGNS BE LIABLE TO YOU OR
            ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL OR CONSEQUENTIAL DAMAGES INCLUDING, WITHOUT LIMITATION, LOST
            PROFITS, PERSONAL INJURY (INCLUDING DEATH) AND PROPERTY DAMAGE OF
            ANY NATURE WHATSOEVER, THAT RESULT FROM (A) THE USE OF, OR THE
            INABILITY TO USE, NEXTERA ENERGY SITE OR CONTENT, MATERIALS OR
            FUNCTIONS ON ANY SUCH SITE, OR (B) THE CONDUCT OR ACTIONS, WHETHER
            ONLINE OR OFFLINE, OF ANY USER OF THE NEXTERA ENERGY SITE OR ANY
            OTHER PERSON OR ENTITY, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL OUR TOTAL LIABILITY
            TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION WHETHER IN
            CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR
            OTHERWISE EXCEED THE AMOUNT PAID BY YOU, IF ANY, OR $100 (WHICHEVER
            IS LESS) FOR ACCESSING OR PARTICIPATING IN ANY ACTIVITY RELATED TO
            ANY NEXTERA ENERGY SITE. THIS PROVISION SHALL SURVIVE THE
            TERMINATION OF YOUR USE OF THE NEXTERA ENERGY SITES OR NEXTERA
            ENERGY’S TERMINATION OF THESE TERMS OF USE.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            MOREOVER, UNDER NO CIRCUMSTANCES SHALL NEXTERA ENERGY OUR LICENSORS
            AND LICENSEES, OR ANY OF THE FOREGOING ENTITIES' RESPECTIVE
            RESELLERS, DISTRIBUTORS, SERVICE PROVIDERS AND SUPPLIERS BE HELD
            LIABLE FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY OR
            INDIRECTLY FROM AN ACT OF FORCE MAJEURE OR CAUSES BEYOND OUR OR
            THEIR REASONABLE CONTROL INCLUDING, WITHOUT LIMITATION, ACTS OF GOD,
            WAR, EQUIPMENT AND TECHNICAL FAILURES, ELECTRICAL POWER FAILURES OR
            FLUCTUATIONS, STRIKES, LABOR DISPUTES, RIOTS, CIVIL DISTURBANCES,
            SHORTAGES OF LABOR OR MATERIALS, NATURAL DISASTERS, GOVERNMENTAL
            ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS,
            NON-PERFORMANCE OF THIRD PARTIES, OR ANY REASONS BEYOND THE
            REASONABLE CONTROL OF US OR OUR LICENSORS AND LICENSEES, OR ANY OF
            THE FOREGOING ENTITIES' RESPECTIVE RESELLERS, DISTRIBUTORS, SERVICE
            PROVIDERS AND SUPPLIERS. THIS PROVISION SHALL SURVIVE THE
            TERMINATION OF YOUR USE OF THE NEXTERA ENERGY SITES OR NEXTERA
            ENERGY’S TERMINATION OF THESE TERMS OF USE.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            YOU FURTHER ACKNOWLEDGE AND AGREE THAT NEITHER NEXTERA ENERGY OUR
            LICENSORS AND LICENSEES, NOR ANY OF THE FOREGOING ENTITIES'
            RESPECTIVE RESELLERS, DISTRIBUTORS, SERVICE PROVIDERS AND SUPPLIERS
            ARE RESPONSIBLE OR LIABLE FOR (A) ANY INCOMPATIBILITY BETWEEN THE
            NEXTERA ENERGY SITE AND ANY WEB SITE, SERVICE, SOFTWARE OR HARDWARE
            OR (B) ANY DELAYS OR FAILURES YOU MAY EXPERIENCE WITH ANY
            TRANSMISSIONS OR TRANSACTIONS RELATING TO THE NEXTERA ENERGY SITES
            IN AN ACCURATE OR TIMELY MANNER. THIS PROVISION SHALL SURVIVE THE
            TERMINATION OF YOUR USE OF THE NEXTERA ENERGY SITE OR NEXTERA
            ENERGY’S TERMINATION OF THESE TERMS OF USE.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            NEXTERA ENERGY IS NOT RESPONSIBLE FOR TECHNICAL, HARDWARE OR
            SOFTWARE FAILURES OF ANY KIND; LOST OR UNAVAILABLE NETWORK
            CONNECTIONS; INCOMPLETE, GARBLED OR DELAYED COMPUTER TRANSMISSIONS.
            UNDER NO CIRCUMSTANCES WILL NEXTERA ENERGY OR ITS SUPPLIERS BE
            LIABLE FOR ANY DAMAGES OR INJURY THAT RESULT FROM THE USE OF THE
            MATERIALS ON THE NEXTERA ENERGY SITES. THIS PROVISION SHALL SURVIVE
            THE TERMINATION OF YOUR USE OF THE NEXTERA ENERGY SITE OR NEXTERA
            ENERGY’S TERMINATION OF THESE TERMS OF USE.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION AND
            ELSEWHERE IN THESE TERMS OF USE APPLY TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR THE EXCLUSION,
            LIMITATION, OR DISCLAIMER LIABILITY FOR THE CERTAIN PROVISIONS SET
            FORTH IN THESE TERMS OF USE, SO SUCH EXCLUSIONS, LIMITATIONS OR
            DISCLAIMERS MAY NOT APPLY TO YOU.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            INDEMNIFICATION
          </h2>
          <p className="font-tinos text-gray mt-2 text-base">
            You are entirely responsible for maintaining the confidentiality of
            your username(s), password(s), and your account(s), as well as all
            activities that occur under your account(s). You hereby agree to
            indemnify, defend, and hold NextEra Energy, our licensors,
            licensees, distributors, agents, representatives and other
            authorized users, and each of the foregoing entities' respective
            resellers, distributors, service providers and suppliers, and all of
            the foregoing entities’ respective officers, directors, owners,
            employees, agents, representatives and assigns harmless from and
            against any and all losses, damages, liabilities and costs
            (including, without limitation, settlement costs and any legal or
            other fees and expenses for investigating or defending any actions
            or threatened actions) incurred by the indemnified in connection
            with any claim arising out of any breach by you of these terms of
            use or claims arising from your account(s). You shall use your best
            efforts to cooperate with NextEra Energy in the defense of any
            claim. NextEra Energy reserves the right, at NextEra Energy’s own
            expense, to employ separate counsel and assume the exclusive defense
            and control of any matter otherwise subject to indemnification by
            you.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            GOVERNING LAW, JURISDICTION AND VENUE
          </h2>
          <p className="font-tinos text-gray mt-2 text-base">
            You acknowledge and agree that these Terms of Use, and any and all
            transactions entered into utilizing any NextEra Energy Site, shall
            be governed by the laws of the State of Florida without regard to
            its choice of law provisions. By utilizing the NextEra Energy Site,
            you further acknowledge and agree that any dispute or claim
            resulting from use of the NextEra Energy Site or the content thereof
            shall be subject to the exclusive jurisdiction of the state and
            federal courts located in Florida, submit to the exclusive
            jurisdiction of such courts with regard to any such dispute or
            claim, and waive any and all objections to the exclusive
            jurisdiction of such courts. If a provision of these Terms of Use is
            held invalid or unenforceable under any applicable law, such
            invalidity or unenforceability shall attach only to such provision
            or part thereof and the remaining part of such provision and all
            other provisions hereof shall continue in full force and effect.
            Further, all terms and conditions of these Terms of Use shall be
            deemed enforceable to the fullest extent permissible under
            applicable law, and when necessary, the court is requested to reform
            any and all terms or conditions to give them such effect. This
            provision shall survive the termination of your use of the NextEra
            Energy site or NextEra Energy’s termination of these Terms of Use.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            CHANGES TO THE SITE
          </h2>
          <p className="font-tinos text-gray mt-2 text-base">
            NextEra Energy reserves the right, in its sole discretion, to
            change, modify, add or delete portions of these Terms of Use at any
            time without notice, and it is your responsibility to review these
            terms of use for any changes. Your use of the NextEra Energy Site
            following any amendment of these terms of use will signify and
            constitute your assent to and acceptance of such revised Terms of
            Use.
          </p>

          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            TERMINATION
          </h2>
          <p className="font-tinos text-gray mt-2 text-base">
            These Terms of Use are effective until terminated by us for any
            reason in our sole and absolute discretion. You may terminate these
            Terms of Use at any time by discontinuing use of the NextEra Energy
            Site and destroying all materials obtained from any related
            documentation and all copies and installations thereof, whether made
            under these Terms of Use or otherwise. In the event that you
            terminate these Terms of Use, you agree to notify us of such
            termination by sending notice of such termination by certified
            United States mail, postage pre-paid to:
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            We may immediately terminate these Terms of Use with respect to you
            (including your access to the NextEra Energy Site) without cause and
            without notice to you in our sole discretion. Upon termination, you
            must cease use of the NextEra Energy Site and destroy all materials
            obtained from such sites and all copies thereof, whether made under
            these terms of use or otherwise.
          </p>
          <p className="font-tinos text-gray mt-4 text-base">
            We have adopted and implemented a policy that provides for the
            termination, in appropriate circumstances, of users who are repeat
            infringers of copyright.
          </p>
          <p className="font-tinos text-gray mt-4 pb-8 text-base">
            The provisions of these terms of use, which by their nature should
            survive the termination of these terms of use, shall so survive such
            termination.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default TermsConditions;
