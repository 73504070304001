import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const testimonials = [
  {
    id: 1,
    name: "Emily Johnson",
    content:
      "Investing with Next era energy has been a game-changer for me. Their commitment to sustainable energy projects is unmatched and I've seen impressive returns on my investments.",
    location: "San Francisco, USA",
  },
  {
    id: 2,
    name: "Daniel Rodriguez",
    content:
      "Next era has proven to be a reliable partner in the clean energy sector. Their transparent approach and focus on innovation make them a standout choice for anyone passionate about environmentally conscious investing.",
    location: "London, UK",
  },
  {
    id: 3,
    name: "Grace Chen",
    content:
      "I am proud to be associated with NextEra. Their dedication to creating a greener future through strategic investments in renewable energy aligns perfectly with my values. The financial returns are just the icing on the cake!",
    location: "Sydney, Australia",
  },
  {
    id: 4,
    name: "Carlos Morales",
    content:
      "This is not just a company; it's a movement towards a sustainable future. I've been impressed with their portfolio of clean energy initiatives and the positive impact they are making globally.",
    location: "New Mexico, USA",
  },
  {
    id: 5,
    name: "Aisha Gupta",
    content:
      "As an investor, I appreciate the strategic vision of Next era Energy. It's a pleasure to be part of their journey.",
    location: "Mumbai, India",
  },
  {
    id: 6,
    name: "Liam Thompson",
    content:
      "NextEra has exceeded my expectations as an investor. Their team's expertise in the clean energy sector is evident and the returns on my investments reflect the company's commitment to excellence.",
    location: "Toronto, Canada",
  },
  {
    id: 7,
    name: "Sophie Martin",
    content:
      "Investing with Next Era Energy is not just about financial gains; it's about making a positive impact on the planet. I'm proud to contribute to the growth of sustainable energy solutions with this forward-thinking company.",
    location: "New York, USA",
  },
  {
    id: 8,
    name: "Raj Patel",
    content:
      "The company has consistently demonstrated a keen understanding of market trends in clean energy. Their well-curated investment portfolio and commitment to environmental responsibility make them my go-to choice for sustainable investing.",
    location: "Bangalore, India",
  },
  {
    id: 9,
    name: "Mia Andersson",
    content:
      "I chose NextEraEnergy for its unwavering dedication to driving positive change through green investments. The company's performance and impact on the clean energy landscape make it a trusted partner in my investment journey.",
    location: "Stockholm, Sweden",
  },
  {
    id: 10,
    name: "Alexandre Dubois",
    content:
      "Next era energy stands out in the financial world by combining profitability with a commitment to a cleaner planet. I've experienced consistent growth in my portfolio while contributing to a more sustainable future.",
    location: "Montreal, Canada",
  },
];

const Testimonials = () => {
  return (
    <>
      <Header />
      <main className="mt-[78.22px]" style={{ background: "white" }}>
        <div className="text-center bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h1 className="text-3xl md:text-4xl font-light text-white">
            Testimonials
          </h1>
        </div>

        <section className="bg-gray-100 py-8">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
              {testimonials.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="bg-white p-6 rounded-lg shadow-lg"
                >
                  <div className="mb-3">
                    <p className="text-lg font-semibold">{testimonial.name}</p>
                    <p className="text-sm text-gray-500">
                      {testimonial.location}
                    </p>
                  </div>
                  <p className="text-gray-600">{testimonial.content}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Testimonials;
