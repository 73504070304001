import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import pdf from "../../documents/nexteraenergy.pdf";
import StockContext from "../../store/stock-context";

const Profile = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [revenue, setRevenue] = useState("");
  const [className, setClassName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [investmentProgress, setInvestmentProgress] = useState("0%");
  const [shareOutcome, setShareOutcome] = useState(0);
  const [transactions, setTransactions] = useState(null);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [milestone, setMilestone] = useState(100000);

  const navigate = useNavigate();
  const ctx = useContext(StockContext);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "en",
      width: "100%",
      height: "100%",
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: true,
      showFloatingTooltip: true,
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(120, 123, 134, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Stocks",
          symbols: [
            {
              s: "NYSE:NEE",
            },
          ],
        },
      ],
    });
    document.getElementById("myContainer").appendChild(script);
  }, []);

  useEffect(() => {
    setClassName("active");
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setUserLoaded(true);
        setBalance(data.balance);
        setRevenue(data.revenue);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    const getTransactions = async () => {
      if (userLoaded) {
        try {
          const { data } = await axios.post(
            "https://nextera-api-new.onrender.com/api/user-transactions",
            { userId: user?._id }
          );

          setTransactions(data);
          setPageLoading(false);
        } catch (error) {}
      }
    };

    getTransactions();
  }, [user?._id, userLoaded]);

  useEffect(() => {
    if (revenue > 100000) {
      setMilestone(1000000);
    }
  }, [revenue]);

  useEffect(() => {
    const calculateInvestmentProgress = () => {
      const prog = `${(revenue / milestone) * 100}%`;
      setInvestmentProgress(prog);
    };

    calculateInvestmentProgress();
  }, [revenue, milestone]);

  useEffect(() => {
    setShareOutcome(ctx.usdToCalculate / ctx.stockPrice);
  }, [ctx.stockPrice, ctx.usdToCalculate]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li className={className}>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li className={className}>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row">
            <div className="col-lg-4">
              <div className="token-statistics card card-token height-auto">
                <div className="card-innr">
                  <div className="token-balance token-balance-with-icon">
                    <div className="token-balance-text">
                      <h6 className="card-sub-title">Active Balance</h6>
                      <span className="lead">
                        ${balance?.toLocaleString()}.00
                      </span>
                    </div>
                  </div>
                  <div className="token-balance token-balance-s2">
                    <h6 className="card-sub-title">Your Contribution</h6>
                    <ul className="token-balance-list">
                      <li className="token-balance-sub">
                        <span className="lead">{user?.shares}</span>
                        <span className="sub">SHARES</span>
                      </li>
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${revenue?.toLocaleString()}
                        </span>
                        <span className="sub">REVENUE</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="token-information card card-full-height">
                <div className="row no-gutters height-100">
                  <div className="col-md-6 text-center">
                    <div className="token-info">
                      <h1 className="token-info-head text-light text-2xl">
                        1 SHARE = ${ctx.stockPrice.toFixed(2)}
                      </h1>
                      {user?.plan && (
                        <p className="text-light text-lg">{user?.plan}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="token-info bdr-tl">
                      <div>
                        <ul className="token-info-list">
                          <li>
                            <span>Share Name:</span>NextEra Energy, Inc.
                          </li>
                          <li>
                            <span>Share Symbol:</span>NEE
                          </li>
                        </ul>
                        <a
                          href={pdf}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <em className="fas fa-download mr-3" />
                          Prospectus
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="token-transaction card card-full-height">
                <div className="card-innr">
                  <div className="card-head has-aside">
                    <h4 className="card-title">Transactions</h4>
                    <div className="card-opt">
                      <Link to="/transactions" className="link ucap">
                        View ALL <em className="fas fa-angle-right ml-2" />
                      </Link>
                    </div>
                  </div>
                  {transactions?.length > 0 ? (
                    <table className="table tnx-table">
                      <thead>
                        <tr>
                          <th>Shares</th>
                          <th>Amount</th>
                          <th className="d-none d-sm-table-cell tnx-date">
                            Date
                          </th>
                          <th className="tnx-type">
                            <div className="tnx-type-text" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction) => (
                          <tr key={transaction._id}>
                            <td>
                              <div className="d-flex align-items-center">
                                {transaction.status === "Rejected" && (
                                  <div className="data-state data-state-canceled" />
                                )}
                                {transaction.status === "Pending" && (
                                  <div className="data-state data-state-progress" />
                                )}
                                {transaction.status === "Confirmed" && (
                                  <div className="data-state data-state-approved" />
                                )}
                                <span className="lead">
                                  {transaction.shares}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span>
                                <span className="lead">
                                  {transaction.usd.toLocaleString()}
                                </span>
                                <span className="sub">USD</span>
                              </span>
                            </td>
                            <td className="d-none d-sm-table-cell tnx-date">
                              <span className="sub sub-s2">
                                {new Date(
                                  transaction.createdAt
                                ).toLocaleString()}
                              </span>
                            </td>
                            {transaction.type === "Deposit" && (
                              <td className="tnx-type">
                                <span className="tnx-type-md badge badge-outline badge-success badge-md border-[blue]">
                                  Purchase
                                </span>
                                <span className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md border-[blue]">
                                  P
                                </span>
                              </td>
                            )}
                            {transaction.type === "Withdrawal" && (
                              <td className="tnx-type">
                                <span className="tnx-type-md badge badge-outline badge-success badge-md border-[orange]">
                                  Withdrawal
                                </span>
                                <span className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md border-[orange]">
                                  W
                                </span>
                              </td>
                            )}
                            {transaction.type === "Credited" && (
                              <td className="tnx-type">
                                <span className="tnx-type-md badge badge-outline badge-success badge-md">
                                  Credited
                                </span>
                                <span className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md">
                                  C
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="h-[178px]">
                      <p className="text-center relative top-1/2 translate-y-[-50%]">
                        No transactions yet
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="token-calculator card card-full-height">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Share Calculation</h4>
                    <p className="card-title-text">
                      Enter amount to calculate shares
                    </p>
                  </div>
                  <div className="token-calc">
                    <div className="token-pay-amount">
                      <input
                        id="token-base-amount"
                        className="input-bordered input-with-hint"
                        type="number"
                        value={ctx.usdToCalculate}
                        onChange={(e) => ctx.setUsd(e.target.value)}
                      />
                      <div className="token-pay-currency">
                        <p
                          className="link ucap link-light toggle-tigger toggle-caret"
                          style={{ cursor: "text" }}
                        >
                          USD
                        </p>
                      </div>
                    </div>
                    <div className="token-received">
                      <div className="token-eq-sign">=</div>
                      <div className="token-received-amount">
                        <h5 className="token-amount">
                          {Math.round(shareOutcome)}
                        </h5>
                        <div className="token-symbol">Shares</div>
                      </div>
                    </div>
                  </div>
                  <div className="token-calc-note note note-plane">
                    <em className="fas fa-info-circle text-light" />
                    <span className="note-text text-light">
                      Amount calculated based on current share price
                    </span>
                  </div>
                  <div className="token-buy">
                    <Link to="/buy-shares" className="btn btn-primary">
                      Buy Shares
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="token-sale-graph card card-full-height">
                <div className="card-innr">
                  <div className="card-head has-aside">
                    <h4 className="card-title">Share Price Chart</h4>
                  </div>
                  <div className="chart-tokensale" id="myContainer">
                    <div className="tradingview-widget-container">
                      <div className="tradingview-widget-container__widget"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="token-sales card card-full-height">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Investment Progress</h4>
                  </div>
                  <ul className="progress-info">
                    <li>
                      <span>Earned</span> {revenue?.toLocaleString()} USD
                    </li>
                    <li className="text-right">
                      <span>GOAL</span>{" "}
                      {revenue <= 100000 ? "100,000 USD" : "1,000,000 USD"}
                    </li>
                  </ul>
                  <div className="progress-bar">
                    <div
                      className="progress-hcap"
                      style={{ width: "100%" }}
                    ></div>
                    <div
                      className="progress-percent"
                      style={{ width: investmentProgress }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
    </div>
  );
};

export default Profile;
