import React from "react";
import { Link } from "react-router-dom";
import pdf from "../documents/nexteraenergy.pdf";

const ProfileFooter = () => {
  return (
    <div className="footer-bar">
      <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8">
            <ul className="footer-links">
              <li>
                <a href={pdf} target="_blank" rel="noreferrer">
                  Prospectus
                </a>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mt-2 mt-sm-0">
            <div className="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x">
              <div className="copyright-text">
                © {new Date(Date.now()).getFullYear()} NextEra Energy.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileFooter;
