import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);

  const id = useParams();
  const navigate = useNavigate();

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showSuccess = () => {
    toast.success("Password reset successfully", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    if (password.length < 6) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordFieldEmpty(true);
      return;
    } else {
      setConfirmPasswordFieldEmpty(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    setLoading(true);

    try {
      await axios.put(
        `https://nextera-api-new.onrender.com/api/auth/passwordreset/${id.resetToken}`,
        {
          password,
        },
        config
      );

      setLoading(false);
      showSuccess();
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      showError();
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">Confirm New Password</h2>
            <form onSubmit={resetPasswordHandler}>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="New Password"
                  className="input-bordered"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordFieldEmpty(false);
                    setPasswordWeak(false);
                  }}
                />
                {passwordFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
                {passwordWeak && (
                  <p className="mt-3 text-red text-sm">
                    Password should be at least 6 characters
                  </p>
                )}
              </div>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="input-bordered"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordFieldEmpty(false);
                  }}
                />
                {confirmPasswordFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
                {passwordError && (
                  <p className="mt-3 text-red text-sm">
                    Passwords do not match
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {loading ? (
                    <button className="btn btn-primary btn-block" disabled>
                      Confirming...
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block">
                      Confirm
                    </button>
                  )}
                </div>
                <div>
                  <Link to="/login">Return to Login</Link>
                </div>
              </div>
              <div className="gaps-2x" />
            </form>
          </div>

          <ToastContainer />

          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
              <li>
                &copy; {new Date(Date.now()).getFullYear()} NextEra Energy.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src={logo} alt="bgimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
