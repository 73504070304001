import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";

const AdminTransactionDetails = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [transaction, setTransaction] = useState();
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userTransacted, setUserTransacted] = useState({});
  const [userIdTransacted, setUserIdTransacted] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const toggleOptions = () => {
    setOptionsOpen((prevState) => !prevState);
  };

  const confirmTransaction = async () => {
    setLoading(true);

    try {
      await axios.post(
        "https://nextera-api-new.onrender.com/api/confirm-transaction",
        {
          idToConfirm: id,
        }
      );

      setLoading(false);
      setConfirmModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const rejectTransaction = async () => {
    setLoading(true);

    try {
      await axios.post(
        "https://nextera-api-new.onrender.com/api/reject-transaction",
        {
          idToReject: id,
        }
      );

      setLoading(false);
      setConfirmModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    try {
      const getTransactionDetails = async () => {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/transaction-details",
          { id }
        );

        setUserIdTransacted(data.userId);
        setTransaction(data);
      };

      getTransactionDetails();
    } catch (error) {}
  }, [id]);

  useEffect(() => {
    if (userIdTransacted) {
      const getUser = async () => {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user-details",
          { id: userIdTransacted }
        );

        setUserTransacted(data);
      };

      getUser();
    }
  }, [userIdTransacted]);

  useEffect(() => {
    if (user && transaction) {
      setPageLoading(false);
      if (!user.isAdmin) {
        navigate("/profile");
      }
    }
  }, [user, transaction, navigate]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">Transaction Details</h4>
                <div className="d-flex align-items-center guttar-20px">
                  <div className="flex-col d-sm-block d-none">
                    <Link
                      to="/admin-transaction"
                      className="btn btn-sm btn-auto btn-primary"
                    >
                      <em className="fas fa-arrow-left mr-3" />
                      Back
                    </Link>
                  </div>
                  <div className="flex-col d-sm-none">
                    <Link
                      to="/admin-transaction"
                      className="btn btn-icon btn-sm btn-primary"
                    >
                      <em className="fas fa-arrow-left" />
                    </Link>
                  </div>
                  {transaction?.status === "Pending" && (
                    <div className="relative d-inline-block">
                      <button
                        onClick={toggleOptions}
                        className="btn btn-dark btn-sm btn-icon toggle-tigger"
                      >
                        <em className="ti ti-more-alt" />
                      </button>
                      {optionsOpen && (
                        <div className="toggle-class dropdown-content dropdown-content-top-left active">
                          <ul className="dropdown-list">
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setConfirmModalOpen(true);
                                }}
                              >
                                <em className="ti ti-check" /> Confirm
                              </a>
                            </li>
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setRejectModalOpen(true);
                                }}
                              >
                                <em className="ti ti-na" /> Reject
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex flex-wrap align-items-center justify-content-between">
                <div className="fake-class">
                  <span className="data-details-title">Transaction Date</span>
                  <span className="data-details-info">
                    {new Date(transaction?.createdAt).toLocaleString()}
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Transaction ID</span>
                  <span className="data-details-info">
                    {transaction?.transactionId}
                  </span>
                </div>
                <div className="fake-class">
                  {transaction?.status === "Confirmed" && (
                    <span className="badge badge-success ucap">Confirmed</span>
                  )}
                  {transaction?.status === "Pending" && (
                    <span className="badge badge-warning ucap">Pending</span>
                  )}
                  {transaction?.status === "Rejected" && (
                    <span className="badge badge-danger ucap">Rejected</span>
                  )}
                </div>
                <div className="gaps-2x w-100 d-none d-md-block" />
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Transaction Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Type</div>
                  <div className="data-details-des">{transaction?.type}</div>
                </li>
                <li>
                  <div className="data-details-head">Amount</div>
                  <div className="data-details-des">{transaction?.usd}</div>
                </li>
                <li>
                  <div className="data-details-head">User name</div>
                  <div className="data-details-des">{userTransacted?.name}</div>
                </li>
                <li>
                  <div className="data-details-head">User email</div>
                  <div className="data-details-des">
                    {userTransacted?.email}
                  </div>
                </li>
                {transaction?.shares && (
                  <li>
                    <div className="data-details-head">Shares</div>
                    <div className="data-details-des">{transaction.shares}</div>
                  </li>
                )}
                {transaction?.paymentMethod && (
                  <li>
                    <div className="data-details-head">Method</div>
                    <div className="data-details-des">
                      {transaction.paymentMethod}
                    </div>
                  </li>
                )}
                {transaction?.remarks && (
                  <li>
                    <div className="data-details-head">Remark</div>
                    <div className="data-details-des">
                      {transaction.remarks}
                    </div>
                  </li>
                )}
              </ul>
              <div className="gaps-3x" />
              {transaction?.proof && (
                <h6 className="card-sub-title">Uploaded</h6>
              )}
              {transaction?.proof && (
                <ul className="data-details-list">
                  <li>
                    <ul className="data-details-docs">
                      <li>
                        <div className="data-doc-item data-doc-item-lg">
                          <div className="data-doc-image">
                            <img src={transaction.proof} alt="doc" />
                          </div>
                          <ul className="data-doc-actions">
                            <li>
                              <a
                                href={transaction.proof}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <em className="ti ti-import" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
              {transaction?.type === "Withdrawal" && (
                <h6 className="card-sub-title">Payment Information</h6>
              )}
              {transaction?.type === "Withdrawal" &&
                transaction?.paymentMethod === "Cardano" && (
                  <ul className="data-details-list">
                    <li>
                      <div className="data-details-head">Cardano Address</div>
                      <div className="data-details-des">
                        {transaction?.cryptoDetails}
                      </div>
                    </li>
                  </ul>
                )}
              {transaction?.type === "Withdrawal" &&
                transaction?.paymentMethod === "Paypal" && (
                  <ul className="data-details-list">
                    <li>
                      <div className="data-details-head">Paypal Email</div>
                      <div className="data-details-des">
                        {transaction?.paypalEmail}
                      </div>
                    </li>
                  </ul>
                )}
              {transaction?.type === "Withdrawal" &&
                transaction?.paymentMethod === "Bank" && (
                  <ul className="data-details-list">
                    <li>
                      <div className="data-details-head">Account Name</div>
                      <div className="data-details-des">
                        {transaction?.accountName}
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">Account Number</div>
                      <div className="data-details-des">
                        {transaction?.accountNumber}
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">Account Type</div>
                      <div className="data-details-des">
                        {transaction?.accountType}
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">Bank Address</div>
                      <div className="data-details-des">
                        {transaction?.bankAddress}
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">Bank Name</div>
                      <div className="data-details-des">
                        {transaction?.bankName}
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">Routing Number</div>
                      <div className="data-details-des">
                        {transaction?.routingNumber}
                      </div>
                    </li>
                  </ul>
                )}
            </div>
          </div>
        </div>
      </div>

      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {confirmModalOpen && <div className="modal-backdrop fade show" />}
      {confirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setConfirmModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to confirm this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Confirming...
                  </button>
                ) : (
                  <button
                    onClick={confirmTransaction}
                    className="btn btn-primary"
                  >
                    Yes, Confirm
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {rejectModalOpen && <div className="modal-backdrop fade show" />}
      {rejectModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setRejectModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to reject this transaction?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Rejecting...
                  </button>
                ) : (
                  <button
                    onClick={rejectTransaction}
                    className="btn btn-primary"
                  >
                    Yes, Reject
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTransactionDetails;
