import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import Pagination from "../../components/Pagination";

const Activity = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [activity, setActivity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = activity?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(activity?.length / recordsPerPage);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const clearlog = async () => {
    setLoading(true);

    try {
      await axios.put(
        "https://nextera-api-new.onrender.com/api/clear-activity",
        {
          userId: user?._id,
        }
      );

      setLoading(false);
      setConfirmModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
      setConfirmModalOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    try {
      const getActivity = async () => {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/activity",
          { userId: user?._id }
        );

        setActivity(data);
      };

      getActivity();
    } catch (error) {}
  }, [user?._id]);

  useEffect(() => {
    if (user && activity) {
      setTimeout(() => {
        setPageLoading(false);
      }, 600);
    }
  }, [user, activity]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row">
            <div className="main-content" style={{ width: "100%" }}>
              <div className="card content-area">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Activity</h4>
                  </div>
                  {activity?.length > 0 && (
                    <div className="card-text">
                      <p>
                        Here is your recent activities log. You can clear your
                        log or disable tracking option from security settings.
                      </p>
                    </div>
                  )}
                  {activity?.length > 0 && (
                    <div className="flex-col d-sm-block my-3">
                      <button
                        className="btn btn-sm btn-auto btn-primary"
                        onClick={() => setConfirmModalOpen(true)}
                      >
                        Clear Log
                      </button>
                    </div>
                  )}

                  {activity?.length > 0 ? (
                    <table
                      className="data-table dt-init activity-table"
                      data-items={10}
                    >
                      <thead>
                        <tr className="data-item data-head">
                          <th className="data-col activity-time">
                            <span>Date</span>
                          </th>
                          <th className="data-col activity-device">
                            <span>Device</span>
                          </th>
                          <th className="data-col activity-browser">
                            <span>Browser</span>
                          </th>
                          <th className="data-col activity-ip">
                            <span>IP</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.map((a) => (
                          <tr className="data-item" key={a._id}>
                            <td className="data-col activity-time">
                              {new Date(a.createdAt).toLocaleString()}
                            </td>
                            <td className="data-col activity-device">{a.os}</td>
                            <td className="data-col activity-browser">
                              {a.browser}
                            </td>
                            <td className="data-col activity-ip">{a.ip}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="h-[472px]">
                      <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                        No user activity
                      </p>
                    </div>
                  )}
                  {activity?.length > 10 && (
                    <Pagination
                      nPages={nPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {confirmModalOpen && <div className="modal-backdrop fade show" />}
      {confirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setConfirmModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to clear your activity log?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Clearing...
                  </button>
                ) : (
                  <button onClick={clearlog} className="btn btn-primary">
                    Yes, Clear
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Activity;
