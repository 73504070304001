import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import paypal from "../../assets/pay-c.png";
import cardano from "../../assets/cardano-ada-icon.png";
import bank from "../../assets/building-columns-solid.svg";
import StockContext from "../../store/stock-context";

const Withdraw = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [revenue, setRevenue] = useState("");
  const [className, setClassName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [investmentProgress, setInvestmentProgress] = useState("0%");
  const [allowWithdrawal, setAllowWithdrawal] = useState(false);
  const [amount, setAmount] = useState("");
  const [paymentMethodChosen, setPaymentMethodChosen] = useState(false);
  const [detailsAdded, setDetailsAdded] = useState(false);
  const [bankMethodSelected, setBankMethodSelected] = useState(false);
  const [cryptoMethodSelected, setCryptoMethodSelected] = useState(false);
  const [paypalMethodSelected, setPaypalMethodSelected] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [cryptoDetails, setCryptoDetails] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [bankDetailsComplete, setBankDetailsComplete] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [shareOutcome, setShareOutcome] = useState("");
  const [loading, setLoading] = useState(false);
  const [milestone, setMilestone] = useState(100000);

  const navigate = useNavigate();
  const ctx = useContext(StockContext);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const selectBankMethod = () => {
    setBankMethodSelected(true);
    setPaymentMethodChosen(true);
    setCryptoMethodSelected(false);
    setPaypalMethodSelected(false);
    setPaymentMethod("Bank");
  };

  const selectCryptoMethod = () => {
    setCryptoMethodSelected(true);
    setPaymentMethodChosen(true);
    setBankMethodSelected(false);
    setPaypalMethodSelected(false);
    setPaymentMethod("Cardano");
  };

  const selectPaypalMethod = () => {
    setPaypalMethodSelected(true);
    setPaymentMethodChosen(true);
    setCryptoMethodSelected(false);
    setBankMethodSelected(false);
    setPaymentMethod("Paypal");
  };

  const confirmWithdrawal = async () => {
    setLoading(true);

    try {
      await axios.post("https://nextera-api-new.onrender.com/api/withdraw", {
        accountName,
        accountNumber,
        accountType,
        bankAddress,
        bankName,
        routingNumber,
        cryptoDetails,
        paypalEmail,
        usd: amount,
        paymentMethod,
        shares: shareOutcome,
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
      });

      setTimeout(() => {
        setLoading(false);
        setReviewModalOpen(true);
      }, 1500);
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      accountName &&
      accountNumber &&
      accountType &&
      bankAddress &&
      bankName &&
      routingNumber
    ) {
      setBankDetailsComplete(true);
    } else {
      setBankDetailsComplete(false);
    }
  }, [
    accountName,
    accountNumber,
    accountType,
    bankAddress,
    bankName,
    routingNumber,
  ]);

  useEffect(() => {
    if (bankDetailsComplete || paypalEmail || cryptoDetails) {
      setDetailsAdded(true);
    } else {
      setDetailsAdded(false);
    }
  }, [bankDetailsComplete, cryptoDetails, paypalEmail]);

  useEffect(() => {
    setClassName("active");
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);
        setRevenue(data.revenue);
        setPageLoading(false);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    if (revenue > 100000) {
      setMilestone(1000000);
    }
  }, [revenue]);

  useEffect(() => {
    const calculateInvestmentProgress = () => {
      const prog = `${(revenue / milestone) * 100}%`;
      setInvestmentProgress(prog);
    };

    calculateInvestmentProgress();
  }, [revenue, milestone]);

  useEffect(() => {
    setShareOutcome(Math.round(amount / ctx.stockPrice));
  }, [amount, ctx.stockPrice]);

  useEffect(() => {
    if (
      paymentMethodChosen &&
      +amount &&
      +amount >= 1000 &&
      +amount <= +balance &&
      detailsAdded &&
      +shareOutcome <= +user?.shares
    ) {
      setAllowWithdrawal(true);
    } else {
      setAllowWithdrawal(false);
    }
  }, [
    amount,
    paymentMethodChosen,
    balance,
    detailsAdded,
    shareOutcome,
    user?.shares,
  ]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row">
            <div className="main-content col-lg-8">
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 1
                    </span>
                    <h4 className="card-title">Select Payment Method</h4>
                  </div>
                  <div className="card-text">
                    <p>
                      Please select your preferred option to be used in
                      receiving funds.
                    </p>
                  </div>
                  <div className="token-currency-choose">
                    <div className="row guttar-15px">
                      <div className="col-6">
                        <div className="pay-option" onClick={selectBankMethod}>
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="payeth"
                            name="payOption"
                          />
                          <label className="pay-option-label" htmlFor="payeth">
                            <span className="pay-title">
                              <img
                                src={bank}
                                style={{ height: "40px" }}
                                alt="bank"
                              />
                            </span>
                            <span className="pay-amount">BANK</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          className="pay-option"
                          onClick={selectCryptoMethod}
                        >
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="paylte"
                            name="payOption"
                          />
                          <label className="pay-option-label" htmlFor="paylte">
                            <span className="pay-title">
                              <img
                                src={cardano}
                                style={{ height: "40px" }}
                                alt="cardano"
                              />
                            </span>
                            <span className="pay-amount">CARDANO</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          className="pay-option"
                          onClick={selectPaypalMethod}
                        >
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="paybtc"
                            name="payOption"
                          />
                          <label className="pay-option-label" htmlFor="paybtc">
                            <span className="pay-title">
                              <img
                                src={paypal}
                                style={{ height: "40px" }}
                                alt="paypal"
                              />
                            </span>
                            <span className="pay-amount">PAYPAL</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pay-notes">
                    <div className="note note-plane note-light note-md font-italic"></div>
                  </div>
                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 2
                    </span>
                    <h4 className="card-title">Input Payment Details</h4>
                    {!paymentMethodChosen && (
                      <p className="mt-3">
                        Please choose a preferred payment method.
                      </p>
                    )}
                    {bankMethodSelected && (
                      <div className="my-3">
                        <p className="mb-1">Bank Name:</p>
                        <div className="copy-wrap mgb-0-5x mb-3">
                          <span className="copy-feedback" />
                          <input
                            type="text"
                            className="copy-address pl-3"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                          />
                        </div>
                        <p className="mb-1">Bank Address:</p>
                        <div className="copy-wrap mgb-0-5x mb-3">
                          <span className="copy-feedback" />
                          <input
                            type="text"
                            className="copy-address pl-3"
                            value={bankAddress}
                            onChange={(e) => setBankAddress(e.target.value)}
                          />
                        </div>
                        <p className="mb-1">Routing (ABA):</p>
                        <div className="copy-wrap mgb-0-5x mb-3">
                          <span className="copy-feedback" />
                          <input
                            type="number"
                            className="copy-address pl-3"
                            value={routingNumber}
                            onChange={(e) => setRoutingNumber(e.target.value)}
                          />
                        </div>
                        <p className="mb-1">Account Number:</p>
                        <div className="copy-wrap mgb-0-5x mb-3">
                          <span className="copy-feedback" />
                          <input
                            type="number"
                            className="copy-address pl-3"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />
                        </div>
                        <p className="mb-1">Account Type:</p>
                        <div className="copy-wrap mgb-0-5x mb-3">
                          <span className="copy-feedback" />
                          <input
                            type="text"
                            className="copy-address pl-3"
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                          />
                        </div>
                        <p className="mb-1">Account Name:</p>
                        <div className="copy-wrap mgb-0-5x mb-3">
                          <span className="copy-feedback" />
                          <input
                            type="text"
                            className="copy-address pl-3"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {cryptoMethodSelected && (
                      <div className="copy-wrap mgb-0-5x">
                        <p className="my-3">Cardano(ADA) address:</p>
                        <input
                          type="text"
                          className="copy-address pl-3"
                          value={cryptoDetails}
                          onChange={(e) => setCryptoDetails(e.target.value)}
                        />
                      </div>
                    )}
                    {paypalMethodSelected && (
                      <div className="copy-wrap mgb-0-5x">
                        <p className="my-3">Email address:</p>
                        <input
                          type="email"
                          className="copy-address pl-3"
                          value={paypalEmail}
                          onChange={(e) => setPaypalEmail(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pay-notes">
                    <div className="note note-plane note-light note-md font-italic"></div>
                  </div>
                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 3
                    </span>
                    <h4 className="card-title">Choose Withdrawal Amount</h4>
                  </div>
                  <div className="token-pay-amount">
                    <input
                      id="token-base-amount"
                      className="input-bordered input-with-hint"
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className="token-pay-currency">
                      <span className="input-hint input-hint-sap">USD</span>
                    </div>
                  </div>
                  <div className="token-calc-note note note-plane mt-2">
                    <em className="fas fa-times-circle text-danger" />
                    <span className="note-text text-light">
                      1,000 USD minimum allowed.
                    </span>
                  </div>
                  {allowWithdrawal && !loading && (
                    <button
                      className="btn btn-primary mt-4"
                      onClick={confirmWithdrawal}
                    >
                      Withdraw
                    </button>
                  )}
                  {!allowWithdrawal && !loading && (
                    <button className="btn btn-primary mt-4" disabled>
                      Withdraw
                    </button>
                  )}
                  {loading && (
                    <button className="btn btn-primary mt-4" disabled>
                      Processing...
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="aside sidebar-right col-lg-4">
              <div className="token-statistics card card-token height-auto">
                <div className="card-innr">
                  <div className="token-balance">
                    <div className="token-balance-text">
                      <h6 className="card-sub-title">Active Balance</h6>
                      <span className="lead">
                        ${balance?.toLocaleString()}.00
                      </span>
                    </div>
                  </div>
                  <div className="token-balance token-balance-s2">
                    <h6 className="card-sub-title">Your Contribution</h6>
                    <ul className="token-balance-list">
                      <li className="token-balance-sub">
                        <span className="lead">{user?.shares}</span>
                        <span className="sub">SHARES</span>
                      </li>
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${revenue?.toLocaleString()}
                        </span>
                        <span className="sub">REVENUE</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="token-sales card">
                <div className="card-innr">
                  <div className="card-head">
                    <h5 className="card-title card-title-sm">
                      Investment Progress
                    </h5>
                  </div>
                  <ul className="progress-info">
                    <li>
                      <span>Earned</span> {revenue?.toLocaleString()} USD
                    </li>
                    <li className="text-right">
                      <span>GOAL</span>{" "}
                      {revenue <= 100000 ? "100,000 USD" : "1,000,000 USD"}
                    </li>
                  </ul>
                  <div className="progress-bar">
                    <div
                      className="progress-hcap"
                      style={{ width: "100%" }}
                    ></div>
                    <div
                      className="progress-percent"
                      style={{ width: investmentProgress }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {reviewModalOpen && <div className="modal-backdrop fade show" />}
      <div
        className="modal fade show"
        id="pay-review"
        tabIndex={-1}
        style={{ display: reviewModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-md modal-dialog-centered">
          <div className="modal-content">
            <div className="popup-body text-center">
              <div className="gaps-2x" />
              <div className="pay-status pay-status-success">
                <em className="ti ti-check" />
              </div>
              <div className="gaps-2x" />
              <h3>We’re reviewing your request</h3>
              <p>
                We’ll review your request and get back to you as soon as
                possible. Once confirmed you'll receive an email confirming your
                transaction.
              </p>
              <div className="gaps-2x" />
              <button
                className="btn btn-primary bg-green"
                type="button"
                onClick={() => {
                  setReviewModalOpen(false);
                  window.location.reload();
                }}
              >
                Close
              </button>
              <div className="gaps-1x" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
