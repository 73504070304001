import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDaKQZLdm_sCgQQ5vR5cVlOVb-o9W4N2Qk",
  authDomain: "stocks-era.firebaseapp.com",
  projectId: "stocks-era",
  storageBucket: "stocks-era.appspot.com",
  messagingSenderId: "781470078905",
  appId: "1:781470078905:web:ca2302a35c4b7cd1408868",
};

const app = initializeApp(firebaseConfig);

export default app;
