import React from "react";

const StockContext = React.createContext({
  usdToCalculate: 0,
  shareOutcome: 0,
  stockPrice: 0,
  setUsd: () => {},
});

export default StockContext;
