import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CustomerService = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [firstNameFieldEmpty, setFirstNameFieldEmpty] = useState(false);
  const [lastNameFieldEmpty, setLastNameFieldEmpty] = useState(false);
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [subjectFieldEmpty, setSubjectFieldEmpty] = useState(false);
  const [contentFieldEmpty, setContentFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const showToastMessage = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const navigate = useNavigate();

  const sendMessage = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!firstName) {
      setFirstNameFieldEmpty(true);
      return;
    } else {
      setFirstNameFieldEmpty(false);
    }

    if (!lastName) {
      setLastNameFieldEmpty(true);
      return;
    } else {
      setLastNameFieldEmpty(false);
    }

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!subject) {
      setSubjectFieldEmpty(true);
      return;
    } else {
      setSubjectFieldEmpty(false);
    }

    if (!content) {
      setContentFieldEmpty(true);
      return;
    } else {
      setContentFieldEmpty(false);
    }

    try {
      setLoading(true);

      await axios.post("https://nextera-api-new.onrender.com/api/message", {
        firstName,
        lastName,
        email,
        subject,
        content,
      });

      setLoading(false);
      navigate("/customer-service-sent");
    } catch (error) {
      setLoading(false);
      showToastMessage();
    }
  };

  return (
    <>
      <Header />
      <main className="mt-[78.22px]">
        <div className="text-center text-white bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h1
            className="text-3xl md:text-4xl font-light"
            style={{ color: "white" }}
          >
            Customer Service
          </h1>
        </div>

        <div
          className="page-content"
          style={{
            paddingTop: "2rem",
            paddingBottom: "3rem",
            backgroundColor: "#f2f2f2",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="content-area card">
                  <div className="card-innr card-innr-fix">
                    <div className="card-head">
                      <h6
                        className="card-title"
                        style={{
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          color: "#6e81a9",
                        }}
                      >
                        Contact Form
                      </h6>
                    </div>
                    <form onSubmit={sendMessage}>
                      <div className="input-item input-with-label">
                        <label
                          className="input-item-label text-exlight"
                          htmlFor="firstName"
                        >
                          First Name
                        </label>
                        <input
                          className="input-bordered"
                          type="text"
                          id="firstName"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setFirstNameFieldEmpty(false);
                          }}
                        />
                        {firstNameFieldEmpty && (
                          <p className="mt-3 text-red text-sm">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="input-item input-with-label">
                        <label
                          className="input-item-label text-exlight"
                          htmlFor="lastName"
                        >
                          Last Name
                        </label>
                        <input
                          className="input-bordered"
                          type="text"
                          id="lastName"
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setLastNameFieldEmpty(false);
                          }}
                        />
                        {lastNameFieldEmpty && (
                          <p className="mt-3 text-red text-sm">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="input-item input-with-label">
                        <label
                          className="input-item-label text-exlight"
                          htmlFor="email"
                        >
                          Your Email
                        </label>
                        <input
                          className="input-bordered"
                          type="text"
                          id="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailFieldEmpty(false);
                          }}
                        />
                        {emailFieldEmpty && (
                          <p className="mt-3 text-red text-sm">
                            Please enter a valid email address
                          </p>
                        )}
                        <span className="input-note">
                          We'll never share your email with anyone else
                        </span>
                      </div>
                      <div className="input-item input-with-label">
                        <label
                          className="input-item-label text-exlight"
                          htmlFor="subject"
                        >
                          Subject
                        </label>
                        <input
                          className="input-bordered"
                          type="text"
                          id="subject"
                          onChange={(e) => {
                            setSubject(e.target.value);
                            setSubjectFieldEmpty(false);
                          }}
                        />
                        {subjectFieldEmpty && (
                          <p className="mt-3 text-red text-sm">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="input-item input-with-label">
                        <label
                          className="input-item-label text-exlight"
                          htmlFor="message"
                        >
                          Your Message
                        </label>
                        <textarea
                          className="input-bordered input-textarea"
                          defaultValue={""}
                          id="message"
                          onChange={(e) => {
                            setContent(e.target.value);
                            setContentFieldEmpty(false);
                          }}
                        />
                        {contentFieldEmpty && (
                          <p className="mt-3 text-red text-sm">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="gaps-1x" />
                      <ToastContainer />
                      {loading ? (
                        <button className="btn btn-primary" disabled>
                          Sending...
                        </button>
                      ) : (
                        <button className="btn btn-primary">
                          Send Message
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default CustomerService;
