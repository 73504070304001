import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import StockContext from "../../store/stock-context";
import cardano from "../../assets/cardano-ada-icon.png";
// import bank from "../../assets/building-columns-solid.svg";
import { PiPlantFill } from "react-icons/pi";
import { GiFruitTree, GiForest } from "react-icons/gi";

const BuyShares = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [revenue, setRevenue] = useState("");
  const [className, setClassName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [investmentProgress, setInvestmentProgress] = useState("0%");
  const [shareOutcome, setShareOutcome] = useState(0);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState(0);
  const [accountType, setAccountType] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [routingNumber, setRoutingNumber] = useState(0);
  const [cryptoDetails, setCryptoDetails] = useState("");
  const [bankMethodSelected, setBankMethodSelected] = useState(false);
  const [cryptoMethodSelected, setCryptoMethodSelected] = useState(true);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Crypto");
  const [loading, setLoading] = useState(false);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [qr, setQr] = useState();
  const [milestone, setMilestone] = useState(100000);
  const [plan, setPlan] = useState("");
  const [planRangeCorrect, setPlanRangeCorrect] = useState(false);
  const [validAmountPlan, setValidAmountPlan] = useState(false);
  const [planOneOpen, setPlanOneOpen] = useState(false);
  const [planTwoOpen, setPlanTwoOpen] = useState(false);
  const [planThreeOpen, setPlanThreeOpen] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const ctx = useContext(StockContext);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const togglePaymentModal = () => {
    setPaymentModalOpen((prevState) => !prevState);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const showCopied = () => {
    toast.success("Copied to Clipboard", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      setFileUploaded(true);
    } else {
      setSelectedFile(null);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setFileUploaded(true);
    } else {
      setSelectedFile(null);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const cancelUpload = () => {
    setFileUploaded(false);
    setSelectedFile(null);
  };

  // const selectBankMethod = () => {
  //   setBankMethodSelected(true);
  //   setCryptoMethodSelected(false);
  // };

  const selectCryptoMethod = () => {
    setCryptoMethodSelected(true);
    setBankMethodSelected(false);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const buyShares = (e) => {
    e.preventDefault();

    setLoading(true);

    if (selectedFile) {
      const fileName = new Date().getTime() + selectedFile.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          showError();
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const confirmDeposit = async () => {
              await axios.post(
                "https://nextera-api-new.onrender.com/api/deposit",
                {
                  usd: ctx.usdToCalculate,
                  shareOutcome,
                  paymentMethod,
                  proof: downloadURL,
                  remarks,
                  plan,
                  userId: user?._id,
                  userName: user?.name,
                  userEmail: user?.email,
                }
              );
            };

            confirmDeposit();
            setLoading(false);
            setReviewModalOpen(true);
          });
        }
      );
    } else {
      try {
        const confirmDesposit = async () => {
          await axios.post("https://nextera-api-new.onrender.com/api/deposit", {
            usd: ctx.usdToCalculate,
            shareOutcome,
            paymentMethod,
            remarks,
            plan,
            userId: user?._id,
            userName: user?.name,
            userEmail: user?.email,
          });
        };

        confirmDesposit();
        setTimeout(() => {
          setLoading(false);
          setReviewModalOpen(true);
        }, 1500);
      } catch (error) {
        showError();
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      plan === "Green Starter Plan" &&
      ctx.usdToCalculate >= 1000 &&
      ctx.usdToCalculate <= 37999
    ) {
      setPlanRangeCorrect(true);
    } else if (
      plan === "Eco-Investor Plan" &&
      ctx.usdToCalculate >= 38000 &&
      ctx.usdToCalculate <= 193999
    ) {
      setPlanRangeCorrect(true);
    } else if (
      plan === "Renewable Elite Plan" &&
      ctx.usdToCalculate >= 194000
    ) {
      setPlanRangeCorrect(true);
    } else {
      setPlanRangeCorrect(false);
    }
  }, [ctx.usdToCalculate, plan]);

  useEffect(() => {
    if (plan && planRangeCorrect) {
      setValidAmountPlan(true);
    } else {
      setValidAmountPlan(false);
    }
  }, [plan, planRangeCorrect]);

  useEffect(() => {
    setClassName("active");
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);
        setRevenue(data.revenue);
        setPageLoading(false);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    if (revenue > 100000) {
      setMilestone(1000000);
    }
  }, [revenue]);

  useEffect(() => {
    const calculateInvestmentProgress = () => {
      const prog = `${(revenue / milestone) * 100}%`;
      setInvestmentProgress(prog);
    };

    calculateInvestmentProgress();
  }, [revenue, milestone]);

  useEffect(() => {
    setShareOutcome(ctx.usdToCalculate / ctx.stockPrice);
  }, [ctx.stockPrice, ctx.usdToCalculate]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const { data } = await axios.get(
          "https://nextera-api-new.onrender.com/api/get-details"
        );
        if (data) {
          setAccountName(data.accountName);
          setAccountNumber(data.accountNumber);
          setAccountType(data.accountType);
          setBankAddress(data.bankAddress);
          setBankName(data.bankName);
          setRoutingNumber(data.routingNumber);
          setCryptoDetails(data.cryptoAddress);
          setQr(data.qr);
        }
      } catch (error) {}
    };

    getDetails();
  }, []);

  useEffect(() => {
    if (bankMethodSelected) {
      setPaymentMethod("Bank");
    } else {
      setPaymentMethod("Crypto");
    }
  }, [bankMethodSelected]);

  return (
    <div className="page-user modal-open">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active Balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li className={className}>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <form onSubmit={buyShares}>
        <div className="page-content">
          <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="row">
              <div className="main-content col-lg-8">
                <div className="content-area card">
                  <div className="card-innr">
                    <div className="card-head">
                      <span className="card-sub-title text-primary font-mid">
                        Step 1
                      </span>
                      <h4 className="card-title">Choose investment plan</h4>
                    </div>
                    <div className="card-text">
                      <p>
                        Choose an investment plan that suits you best from our
                        catalog of carefully curated investment plans.
                      </p>
                    </div>

                    <div className="token-currency-choose">
                      <div className="row guttar-15px">
                        <div
                          className="col-12"
                          onClick={() => {
                            setPlan("Green Starter Plan");
                            setPlanOneOpen(true);
                            setPlanTwoOpen(false);
                            setPlanThreeOpen(false);
                          }}
                        >
                          <div className="pay-option">
                            <input
                              className="pay-option-check"
                              type="radio"
                              id="payeth"
                              name="payOption"
                            />
                            <label
                              className="pay-option-label"
                              htmlFor="payeth"
                            >
                              <span className="pay-title">
                                <PiPlantFill
                                  style={{
                                    fontSize: "24px",
                                    marginRight: "10px",
                                  }}
                                />
                                <span className="pay-cur">
                                  Green Starter Plan
                                </span>
                              </span>
                              <span className="pay-amount">
                                $1,000 - $37,999
                              </span>
                            </label>
                          </div>{" "}
                        </div>
                        {planOneOpen && (
                          <div style={{ marginBottom: "14px" }}>
                            <div className="note note-plane note-light">
                              <em className="fas fa-info-circle" />
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "4px",
                                }}
                              >
                                Ideal for those looking to start their green
                                investment journey. Enjoy monthly returns and
                                participate in our internal referral system for
                                added benefits.
                              </p>
                            </div>
                            <ul style={{ fontSize: "13px" }}>
                              <li className="list-disc list-inside">
                                Investment Range: $1,000 - $37,999
                              </li>
                              <li className="list-disc list-inside">
                                Weekly Investment Earnings: 1.3%
                              </li>
                              <li className="list-disc list-inside">
                                Locked-in Investment Earnings: 2.3%
                              </li>
                              <li className="list-disc list-inside">
                                Duration: 6 months
                              </li>
                              <li className="list-disc list-inside">
                                Referral Commission: 5%
                              </li>
                            </ul>
                          </div>
                        )}

                        <div
                          className="col-12"
                          onClick={() => {
                            setPlan("Eco-Investor Plan");
                            setPlanTwoOpen(true);
                            setPlanOneOpen(false);
                            setPlanThreeOpen(false);
                          }}
                        >
                          <div className="pay-option">
                            <input
                              className="pay-option-check"
                              type="radio"
                              id="paylte"
                              name="payOption"
                            />
                            <label
                              className="pay-option-label"
                              htmlFor="paylte"
                            >
                              <span className="pay-title">
                                <GiFruitTree
                                  style={{
                                    fontSize: "24px",
                                    marginRight: "10px",
                                  }}
                                />
                                <span className="pay-cur">
                                  Eco-Investor Plan
                                </span>
                              </span>
                              <span className="pay-amount">
                                $38,000 - $193,999
                              </span>
                            </label>
                          </div>{" "}
                        </div>
                        {planTwoOpen && (
                          <div style={{ marginBottom: "14px" }}>
                            <div className="note note-plane note-light">
                              <em className="fas fa-info-circle" />
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "4px",
                                }}
                              >
                                A step up for investors committed to sustainable
                                growth. This plan offers higher returns and
                                additional perks, including priority access to
                                new green projects.
                              </p>
                            </div>
                            <ul style={{ fontSize: "13px" }}>
                              <li className="list-disc list-inside">
                                Investment Range: $38,000 - $193,999
                              </li>
                              <li className="list-disc list-inside">
                                Weekly Investment Earnings: 2.3%
                              </li>
                              <li className="list-disc list-inside">
                                Locked-in Investment Earnings: 3.5%
                              </li>
                              <li className="list-disc list-inside">
                                Duration: 12 months
                              </li>
                              <li className="list-disc list-inside">
                                Referral Commission: 11%
                              </li>
                            </ul>
                          </div>
                        )}
                        <div
                          className="col-12"
                          onClick={() => {
                            setPlan("Renewable Elite Plan");
                            setPlanThreeOpen(true);
                            setPlanOneOpen(false);
                            setPlanTwoOpen(false);
                          }}
                        >
                          <div className="pay-option">
                            <input
                              className="pay-option-check"
                              type="radio"
                              id="paybtc"
                              name="payOption"
                            />
                            <label
                              className="pay-option-label"
                              htmlFor="paybtc"
                            >
                              <span className="pay-title">
                                <GiForest
                                  style={{
                                    fontSize: "24px",
                                    marginRight: "10px",
                                  }}
                                />
                                <span className="pay-cur">
                                  Renewable Elite Plan
                                </span>
                              </span>
                              <span className="pay-amount">
                                $194,000 - $576,000
                              </span>
                            </label>
                          </div>{" "}
                        </div>
                        {planThreeOpen && (
                          <div style={{ marginBottom: "14px" }}>
                            <div className="note note-plane note-light">
                              <em className="fas fa-info-circle" />
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "4px",
                                }}
                              >
                                For serious green investors seeking substantial
                                returns. This elite plan provides exclusive
                                benefits, personalized investment consultations,
                                and priority participation in limited green
                                opportunities.
                              </p>
                            </div>
                            <ul style={{ fontSize: "13px" }}>
                              <li className="list-disc list-inside">
                                Investment Range: $194,000 - $576,000
                              </li>
                              <li className="list-disc list-inside">
                                Weekly Investment Earnings: 5%
                              </li>
                              <li className="list-disc list-inside">
                                Locked-in Investment Earnings: 7.7%
                              </li>
                              <li className="list-disc list-inside">
                                Duration: 12 months
                              </li>
                              <li className="list-disc list-inside">
                                Referral Commission: 20%
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      {/* .row */}
                    </div>

                    <div className="card-head mt-8">
                      <span className="card-sub-title text-primary font-mid">
                        Step 2
                      </span>
                      <h4 className="card-title">Calculate number of shares</h4>
                    </div>
                    <div className="card-text">
                      <p>
                        Enter your desired deposit amount and view the number of
                        shares you will receive. The calculator helps to convert
                        required currency to shares.
                      </p>
                    </div>
                    <div className="token-contribute">
                      <div className="token-calc">
                        <div className="token-pay-amount">
                          <input
                            id="token-base-amount"
                            className="input-bordered input-with-hint"
                            type="number"
                            value={ctx.usdToCalculate}
                            onChange={(e) => ctx.setUsd(e.target.value)}
                          />
                          <div className="token-pay-currency">
                            <span className="input-hint input-hint-sap">
                              USD
                            </span>
                          </div>
                        </div>
                        <div className="token-received">
                          <div className="token-eq-sign">=</div>
                          <div className="token-received-amount">
                            <h5 className="token-amount">
                              {Math.round(shareOutcome)}
                            </h5>
                            <div className="token-symbol">SHARES</div>
                          </div>
                        </div>
                      </div>
                      <div className="token-calc-note note note-plane">
                        <em className="fas fa-times-circle text-danger" />
                        {!plan && (
                          <span className="note-text text-light">
                            1,000 USD minimum contribution required.
                          </span>
                        )}
                        {plan === "Green Starter Plan" && (
                          <span className="note-text text-light">
                            1,000 USD minimum contribution required.
                          </span>
                        )}
                        {plan === "Eco-Investor Plan" && (
                          <span className="note-text text-light">
                            38,000 USD minimum contribution required.
                          </span>
                        )}
                        {plan === "Renewable Elite Plan" && (
                          <span className="note-text text-light">
                            194,000 USD minimum contribution required.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="token-overview-wrap">
                      <div className="note note-plane note-danger note-sm pdt-1x pl-0">
                        <p>
                          Your Contribution will be calculated based on exchange
                          rate at the moment your transaction is confirmed.
                        </p>
                      </div>
                    </div>
                    <div className="card-head">
                      <span className="card-sub-title text-primary font-mid">
                        Step 3
                      </span>
                      <h4 className="card-title">Make payment</h4>
                    </div>
                    <div className="card-text">
                      <p>
                        To get shares please make payment. You can send payment
                        directly to any of our payment options. Once your
                        payment is confirmed, you will receive an email
                        confirming your deposit.
                      </p>
                    </div>
                    <div className="pay-buttons">
                      <div className="pay-button">
                        <button
                          type="button"
                          onClick={togglePaymentModal}
                          data-target="#get-pay-address"
                          className="btn btn-light-alt btn-between w-100 bg-[#e6effb]"
                        >
                          Get Payment Details <em className="ti ti-wallet" />
                        </button>
                      </div>
                    </div>
                    <div className="pay-notes">
                      <div className="note note-plane note-light note-md font-italic">
                        <em className="fas fa-info-circle" />
                        <p>
                          Shares will appear in your account after payment is
                          successfully approved by our team.
                        </p>
                      </div>
                    </div>
                    <div className="card-head mt-8">
                      <span className="card-sub-title text-primary font-mid">
                        Step 4
                      </span>
                      <h4 className="card-title">Show Proof of Payment</h4>
                    </div>
                    <div className="card-text">
                      <p>
                        To speed up confirmation of your deposit, we require
                        that you send in proof of your deposit. This can be in
                        the form of a picture/snapshot of your receipt or any
                        other necessary file or a written note under the Remarks
                        section.
                      </p>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col-sm-8">
                        <div className="upload-box" onDragEnter={handleDrag}>
                          <div className="upload-zone dropzone dz-clickable">
                            <input
                              ref={inputRef}
                              type="file"
                              id="input-file-upload"
                              className="hidden"
                              onChange={handleChange}
                              accept="image/*"
                            />
                            {fileUploaded ? (
                              <div
                                className="dz-message"
                                data-dz-message="true"
                              >
                                <label htmlFor="input-file-upload">
                                  <span
                                    className="dz-message-text mb-3 text-lg"
                                    style={{ color: "#007db6" }}
                                  >
                                    {selectedFile.name.length > 20
                                      ? `${selectedFile?.name.substring(
                                          0,
                                          20
                                        )}...`
                                      : selectedFile?.name}
                                  </span>
                                  <button
                                    className="btn btn-primary bg-green"
                                    type="button"
                                    onClick={cancelUpload}
                                  >
                                    CANCEL
                                  </button>
                                </label>
                              </div>
                            ) : (
                              <div
                                className="dz-message"
                                data-dz-message="true"
                              >
                                <label htmlFor="input-file-upload">
                                  <span className="dz-message-text">
                                    Drag and drop file
                                  </span>
                                  <span className="dz-message-or">or</span>
                                  <button
                                    className="btn btn-primary bg-green"
                                    type="button"
                                    onClick={onButtonClick}
                                  >
                                    SELECT
                                  </button>
                                </label>
                              </div>
                            )}
                          </div>
                          {dragActive && (
                            <div
                              className="absolute w-full h-full border-[1rem] top-0 bottom-0 right-0 left-0"
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </div>
                        <div className="note note-plane note-light note-md font-italic mt-3">
                          <em className="fas fa-info-circle" />
                          <p>Uploaded file should be 4MB or less.</p>
                        </div>
                      </div>
                    </div>
                    <div className="input-item input-with-label mt-3">
                      <label htmlFor="remarks" className="input-item-label">
                        Remarks:
                      </label>
                      <textarea
                        className="input-bordered"
                        id="remarks"
                        rows={4}
                        value={remarks}
                        onChange={(e) => {
                          setRemarks(e.target.value);
                        }}
                      />
                    </div>
                    {loading || !validAmountPlan ? (
                      <button className="btn btn-primary" disabled>
                        Confirm Payment
                      </button>
                    ) : (
                      <button className="btn btn-primary">
                        Confirm Payment
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="aside sidebar-right col-lg-4">
                <div className="token-statistics card card-token height-auto">
                  <div className="card-innr">
                    <div className="token-balance">
                      <div className="token-balance-text">
                        <h6 className="card-sub-title">Active Balance</h6>
                        <span className="lead">
                          ${balance?.toLocaleString()}.00
                        </span>
                      </div>
                    </div>
                    <div className="token-balance token-balance-s2">
                      <h6 className="card-sub-title">Your Contribution</h6>
                      <ul className="token-balance-list">
                        <li className="token-balance-sub">
                          <span className="lead">{user?.shares}</span>
                          <span className="sub">SHARES</span>
                        </li>
                        <li className="token-balance-sub">
                          <span className="lead">
                            ${revenue?.toLocaleString()}
                          </span>
                          <span className="sub">REVENUE</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="token-sales card">
                  <div className="card-innr">
                    <div className="card-head">
                      <h5 className="card-title card-title-sm">
                        Investment Progress
                      </h5>
                    </div>
                    <ul className="progress-info">
                      <li>
                        <span>Earned</span> {revenue?.toLocaleString()} USD
                      </li>
                      <li className="text-right">
                        <span>GOAL</span>{" "}
                        {revenue <= 100000 ? "100,000 USD" : "1,000,000 USD"}
                      </li>
                    </ul>
                    <div className="progress-bar">
                      <div
                        className="progress-hcap"
                        style={{ width: "100%" }}
                      ></div>
                      <div
                        className="progress-percent"
                        style={{ width: investmentProgress }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProfileFooter />
        <Loading
          loading={pageLoading}
          background="rgba(255,255,255)"
          loaderColor="rgb(91, 163, 60)"
        />
        {paymentModalOpen && <div className="modal-backdrop fade show" />}
        {reviewModalOpen && <div className="modal-backdrop fade show" />}
        <div
          className="modal fade show"
          id="get-pay-address"
          tabIndex={-1}
          style={{ display: paymentModalOpen ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={togglePaymentModal}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body">
                <h4 className="popup-title">Payment Details for Deposit</h4>
                <p>
                  Please pay deposit amount of{" "}
                  <strong>{ctx.usdToCalculate.toLocaleString()} USD</strong> to
                  any of our payment details and receive{" "}
                  <strong>{Math.round(shareOutcome)} shares</strong>.
                </p>
                <p className="mt-2">
                  You can choose any of the following payment options to make
                  your payment. The shares balance will appear in your account
                  after successful payment and verification.
                </p>
                <h5 className="mgt-1-5x font-mid">Select Payment method:</h5>
                <div className="gaps-1x" />
                <ul className="pay-list guttar-20px">
                  {/* <li className="pay-item" onClick={selectBankMethod}>
                    <input
                      type="radio"
                      className="pay-check"
                      name="pay-option"
                      id="pay-coin"
                      defaultChecked
                    />
                    <label
                      className="pay-check-label pb-3 pt-14 px-12"
                      htmlFor="pay-coin"
                    >
                      <img
                        src={bank}
                        alt="bank"
                        style={{
                          height: "30px",
                          width: "30px",
                          margin: "auto",
                          marginBottom: "3px",
                        }}
                      />
                      <strong>Bank</strong>
                    </label>
                  </li> */}
                  <li className="pay-item" onClick={selectCryptoMethod}>
                    <input
                      type="radio"
                      className="pay-check"
                      name="pay-option"
                      id="pay-coinpay"
                      defaultChecked
                    />
                    <label
                      className="pay-check-label pb-3 pt-14"
                      htmlFor="pay-coinpay"
                    >
                      <img
                        src={cardano}
                        alt="cardano"
                        style={{
                          height: "30px",
                          width: "30px",
                          margin: "auto",
                          marginBottom: "3px",
                        }}
                      />
                      <strong>Cardano(ADA)</strong>
                    </label>
                  </li>
                </ul>
                {bankMethodSelected && (
                  <h6 className="font-bold mb-3">
                    Payment to the following bank account:
                  </h6>
                )}
                {bankMethodSelected && (
                  <div className="mb-3">
                    <p className="mb-1">Bank Name:</p>
                    <div className="copy-wrap mgb-0-5x mb-3">
                      <span className="copy-feedback" />
                      <input
                        type="text"
                        className="copy-address pl-3"
                        value={bankName}
                        disabled
                      />
                      <button
                        type="button"
                        className="copy-trigger copy-clipboard bg-[#e9eff9]"
                        onClick={() => {
                          navigator.clipboard.writeText(bankName);
                          showCopied();
                        }}
                      >
                        <em className="ti ti-files" />
                      </button>
                    </div>
                    <p className="mb-1">Bank Address:</p>
                    <div className="copy-wrap mgb-0-5x mb-3">
                      <span className="copy-feedback" />
                      <input
                        type="text"
                        className="copy-address pl-3"
                        value={bankAddress}
                        disabled
                      />
                      <button
                        type="button"
                        className="copy-trigger copy-clipboard bg-[#e9eff9]"
                        onClick={() => {
                          navigator.clipboard.writeText(bankAddress);
                          showCopied();
                        }}
                      >
                        <em className="ti ti-files" />
                      </button>
                    </div>
                    <p className="mb-1">Routing (ABA):</p>
                    <div className="copy-wrap mgb-0-5x mb-3">
                      <span className="copy-feedback" />
                      <input
                        type="text"
                        className="copy-address pl-3"
                        value={routingNumber}
                        disabled
                      />
                      <button
                        type="button"
                        className="copy-trigger copy-clipboard bg-[#e9eff9]"
                        onClick={() => {
                          navigator.clipboard.writeText(routingNumber);
                          showCopied();
                        }}
                      >
                        <em className="ti ti-files" />
                      </button>
                    </div>
                    <p className="mb-1">Account Number:</p>
                    <div className="copy-wrap mgb-0-5x mb-3">
                      <span className="copy-feedback" />
                      <input
                        type="number"
                        className="copy-address pl-3"
                        value={accountNumber}
                        disabled
                      />
                      <button
                        type="button"
                        className="copy-trigger copy-clipboard bg-[#e9eff9]"
                        onClick={() => {
                          navigator.clipboard.writeText(accountNumber);
                          showCopied();
                        }}
                      >
                        <em className="ti ti-files" />
                      </button>
                    </div>
                    <p className="mb-1">Account Type:</p>
                    <div className="copy-wrap mgb-0-5x mb-3">
                      <span className="copy-feedback" />
                      <input
                        type="text"
                        className="copy-address pl-3"
                        value={accountType}
                        disabled
                      />
                      <button
                        type="button"
                        className="copy-trigger copy-clipboard bg-[#e9eff9]"
                        onClick={() => {
                          navigator.clipboard.writeText(accountType);
                          showCopied();
                        }}
                      >
                        <em className="ti ti-files" />
                      </button>
                    </div>
                    <p className="mb-1">Account Name:</p>
                    <div className="copy-wrap mgb-0-5x mb-3">
                      <span className="copy-feedback" />
                      <input
                        type="text"
                        className="copy-address pl-3"
                        value={accountName}
                        disabled
                      />
                      <button
                        type="button"
                        className="copy-trigger copy-clipboard bg-[#e9eff9]"
                        onClick={() => {
                          navigator.clipboard.writeText(accountName);
                          showCopied();
                        }}
                      >
                        <em className="ti ti-files" />
                      </button>
                    </div>
                  </div>
                )}
                {cryptoMethodSelected && (
                  <h6 className="font-bold mb-2">
                    Payment to the following Cardano(ADA) Address or Scan the QR
                    code below
                  </h6>
                )}
                {cryptoMethodSelected && qr && (
                  <img
                    src={qr}
                    alt="qr"
                    className="h-[150px] w-[150px] mx-auto"
                  />
                )}
                {cryptoMethodSelected && (
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address pl-3"
                      value={cryptoDetails}
                      disabled
                    />
                    <button
                      type="button"
                      className="copy-trigger copy-clipboard bg-[#e9eff9]"
                      onClick={() => {
                        navigator.clipboard.writeText(cryptoDetails);
                        showCopied();
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                )}
                <div className="gaps-1x" />
                {cryptoMethodSelected && (
                  <div className="note note-plane note-light mgb-1x">
                    <em className="fas fa-info-circle"></em>
                    <p>
                      We only accept Cardano(ADA) as alternative payment option.
                      Cardano, also popularly called "the Green Blockchain" is
                      eco-friendly, scalable, and sustainable, all thanks to the
                      use of the first peer-reviewed consensus mechanism named
                      Ouroboros.
                    </p>
                    <p className="mt-2">
                      We recommend using secure Digital currency exchange
                      Coinbase to purchase Cardano (ADA) tokens. To learn more
                      <a
                        href="https://www.coinbase.com/how-to-buy/cardano"
                        className="ml-1"
                      >
                        https://www.coinbase.com/how-to-buy/cardano
                      </a>
                    </p>
                  </div>
                )}
                <div className="note note-plane note-danger">
                  <em className="fas fa-info-circle" />
                  <p>
                    In case you send a different amount, number of shares will
                    update accordingly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade show"
          id="pay-review"
          tabIndex={-1}
          style={{ display: reviewModalOpen ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="pay-status pay-status-success">
                  <em className="ti ti-check" />
                </div>
                <div className="gaps-2x" />
                <h3>We’re reviewing your payment</h3>
                <p>
                  We’ll review your transaction and get back to you as soon as
                  possible. Once confirmed you'll receive an email confirming
                  your transaction.
                </p>
                <div className="gaps-2x" />
                <button
                  className="btn btn-primary bg-green"
                  type="button"
                  onClick={() => {
                    setReviewModalOpen(false);
                    window.location.reload();
                  }}
                >
                  Close
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default BuyShares;
