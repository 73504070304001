import React, { useState, useEffect } from "react";
import StockContext from "./stock-context";
import axios from "axios";

const StockProvider = (props) => {
  const [usdToCalculate, setUsdToCalculate] = useState("");
  const [shareOutcome, setShareOutcome] = useState(0);
  const [stockPrice, setStockPrice] = useState(90.0);

  const setUsd = (n) => {
    setUsdToCalculate(n);
  };

  useEffect(() => {
    const getPrice = async () => {
      try {
        const { data } = await axios.get(
          "https://nextera-api-new.onrender.com/api/stock-price"
        );
        setStockPrice(data);
      } catch (error) {}
    };

    getPrice();
  }, []);

  useEffect(() => {
    setShareOutcome(usdToCalculate / stockPrice);
  }, [stockPrice, usdToCalculate]);

  const stockContext = {
    setUsd,
    shareOutcome,
    usdToCalculate,
    stockPrice,
  };

  return (
    <StockContext.Provider value={stockContext}>
      {props.children}
    </StockContext.Provider>
  );
};

export default StockProvider;
