import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import windmill from "../assets/windmill.jpg";
import fortune from "../assets/fortune.jpg";
import commission from "../assets/commission-free-trades.png";
import fractional from "../assets/icon_RTB_fractional_shares.png";
import research from "../assets/stock_research.png";

const Home = () => {
  const [stockPrice, setStockPrice] = useState(90.0);

  useEffect(() => {
    const getPrice = async () => {
      try {
        const { data } = await axios.get(
          "https://nextera-api-new.onrender.com/api/stock-price"
        );
        setStockPrice(data);
      } catch (error) {}
    };

    getPrice();
  }, []);

  return (
    <>
      <Header />
      <main className="mt-[78.22px]" style={{ background: "white" }}>
        <div className="text-black bg-hero bg-cover md:bg-heroL bg-center bg-no-repeat h-[38rem] md:h-[40rem] xl:h-[30rem] p-9 md:py-16 lg:py-20 lg:px-24 xl:px-32 font-sans">
          <h1 className="text-3xl md:text-4xl font-light">
            Trading at NextEra
          </h1>
          <p className="text-[17.5px] xl:text-2xl font-tinos leading-[1.375] mt-[15px] tracking-wide md:w-[60%] xl:w-[40%]">
            AI-powered Smart trading technology, means Intelligent trading
            decisions for Smarter Investing.
          </p>
          <Link to="/register">
            <button className="bg-green text-white py-[9px] px-[14px] rounded font-semibold mt-6">
              Open a Brokerage Account
            </button>
          </Link>
        </div>

        <div className="text-center p-9 md:py-16 md:px-24 lg:py-20 lg:px-16 xl:px-72 font-sans">
          <h2 className="text-[28px] md:text-4xl font-light text-green">
            Whether you trade a lot or a little, we can help you get ahead,
            while protecting the environment
          </h2>

          <div className="lg:flex lg:justify-between">
            <div className="lg:w-[30%]">
              <img
                src={commission}
                alt="commission-free-trades"
                className="mx-auto my-5"
              />
              <p className="font-bold text-blue">AI-powered trades</p>
              <p className="font-tinos text-gray text-lg tracking-wide mt-1">
                A.I (Artificial Intelligence) makes decisions better than the
                average human. Automated Investing means consistent profit
                margins. Get profit margin rates as high as 5%.
              </p>
            </div>

            <div className="lg:w-[30%]">
              <img
                src={fractional}
                alt="fractional-shares"
                className="mx-auto my-5"
              />
              <p className="font-bold text-blue">Fractional share trading</p>
              <p className="font-tinos text-gray text-lg tracking-wide mt-1">
                Slice stocks makes dollar-based investing easier. Own a slice of
                your favourite Green energy company.
              </p>
            </div>

            <div className="lg:w-[30%]">
              <img
                src={research}
                alt="stock-research"
                className="mx-auto my-5"
              />
              <p className="font-bold text-blue">Decision-making technology</p>
              <p className="font-tinos text-gray text-lg tracking-wide mt-1">
                Get smarter trading technology and dedicated support to help
                inform your trading decisions.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-light text-center p-9 py-16 md:py-16 md:px-24 lg:py-12 lg:px-16 xl:px-72 font-sans">
          <h2 className="text-[28px] md:text-4xl font-light text-green">
            Green and transparency
          </h2>
          <p className="font-bold text-green text-xl my-3">
            Our processes are all Green
          </p>
          <p className="font-tinos text-gray text-lg tracking-wide mt-1">
            We adopt a corporate social responsibility (CSR) to be eco-friendly
            and this includes our trading processes, products, services and
            partners.
          </p>
        </div>

        <div className="p-9 md:py-16 md:px-24 lg:py-20 lg:px-16 xl:px-72 font-sans lg:flex lg:justify-between">
          <div className="lg:w-[48%] lg:border-r lg:border-light lg:pr-10">
            <h2 className="text-blue text-[28px] md:text-4xl font-light">
              Our trading account
            </h2>
            <p className="font-tinos text-lg text-gray tracking-wide mt-4">
              This full-featured brokerage account can help meet your needs as
              you grow as an investor.
            </p>
            <Link to="/register">
              <button className="bg-green text-white py-[9px] px-[14px] rounded font-semibold mt-6">
                Open a Brokerage Account
              </button>
            </Link>
          </div>

          <div className="w-full h-0.5 bg-light mt-7 lg:hidden" />

          <div className="lg:w-[45%]">
            <h2 className="text-blue text-[28px] md:text-4xl font-light mt-4 lg:mt-0 xl:mt-0">
              Getting started trading
            </h2>
            <p className="font-tinos text-lg text-gray tracking-wide mt-4">
              Delve into trading and learn new strategies with timely insights
              and guided education to help you get to your next level.
            </p>
          </div>
        </div>

        <div className="text-center text-white bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h2
            className="text-3xl md:text-4xl font-light"
            style={{ color: "white" }}
          >
            Investor Relations
          </h2>
          <p className="text-lg font-tinos leading-6 mt-[15px]">
            Our core strategy has remained consistent and has led to a long-term
            track record of creating shareholder value.
          </p>
        </div>

        <div className="text-center mt-6 px-5 md:py-5 md:px-24 font-tinos xl:px-52">
          <h2 className="text-gray text-sm tracking-[2px] lg:text-[15px]">
            COMPANY OVERVIEW
          </h2>
          <div className="h-1.5 w-[84px] mt-3 bg-blue mx-auto rounded-md" />
          <h3 className="text-2xl font-light font-sans tracking-[1.25px] mt-8 mb-5 text-black lg:text-[30px]">
            About NextEra Energy
          </h3>
          <p className="text-gray tracking-[0.1px] mb-7 text-base">
            NextEra Energy, Inc. (NYSE: NEE) is a leading clean energy company
            headquartered in Juno Beach, Florida. NextEra Energy owns Florida
            Power &amp; Light Company, which is the largest vertically
            integrated rate-regulated electric utility in the United States as
            measured by retail electricity produced and sold, and serves more
            than 5.7 million customer accounts, supporting more than 11 million
            residents across Florida with clean, reliable and affordable
            electricity. NextEra Energy also owns a competitive clean energy
            business, NextEra Energy Resources, LLC, which, together with its
            affiliated entities, is the world's largest generator of renewable
            energy from the wind and sun and a world leader in battery storage.
            Through its subsidiaries, NextEra Energy generates clean,
            emissions-free electricity from seven commercial nuclear power units
            in Florida, New Hampshire and Wisconsin. A Fortune 200 company,
            NextEra Energy has been recognized often by third parties for its
            efforts in sustainability, corporate responsibility, ethics and
            compliance, and diversity. NextEra Energy is ranked No. 1 in the
            electric and gas utilities industry on Fortune's 2022 list of
            "World's Most Admired Companies," recognized on Fortune’s 2021 list
            of companies that “Change the World” and received the S&amp;P Global
            Platts 2020 Energy Transition Award for leadership in environmental,
            social and governance.
          </p>
          <Link to="/company-overview" style={{ border: "none" }}>
            <button className="text-blue font-sans tracking-[1.2px] border-2 rounded-3xl md:w-3/5 xl:w-2/5 py-2 px-5 w-[206px] mb-4 font-semibold">
              LEARN MORE
            </button>
          </Link>
        </div>

        <div className="p-5 mt-2 md:p-7 md:flex lg:px-28 xl:px-52">
          <img
            src={windmill}
            alt="windmill"
            className="h-[260px] w-full md:w-1/2 md:h-[331px]"
          />
          <div className="bg-light px-4 py-9 md:w-1/2 md:px-9 md:pt-16">
            <h3 className="text-xl font-light font-sans tracking-[1.25px] text-black">
              Stock Quote
            </h3>
            <p className="font-sans text-[15px] text-gray mt-3 mb-1">
              NextEra Energy, Inc. (NYSE)
            </p>
            <p className="font-sans font-light text-gray text-3xl">
              ${stockPrice}
            </p>
          </div>
        </div>

        <img src={fortune} alt="fortune" className="mx-auto pb-5" />
      </main>
      <Footer />
    </>
  );
};

export default Home;
