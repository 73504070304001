import React from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import Activity from "./pages/user/Activity";
import AdminKyc from "./pages/admin/AdminKyc";
import AdminKycDetails from "./pages/admin/AdminKycDetails";
import AdminMessage from "./pages/admin/AdminMessage";
import AdminMessageDetails from "./pages/admin/AdminMessageDetails";
import AdminPayment from "./pages/admin/AdminPayment";
import AdminTransaction from "./pages/admin/AdminTransaction";
import AdminTransactionDetails from "./pages/admin/AdminTransactionDetails";
import AdminUser from "./pages/admin/AdminUser";
import AdminUserDetails from "./pages/admin/AdminUserDetails";
import BuyShares from "./pages/user/BuyShares";
import Home from "./pages/Home";
import Company from "./pages/Company";
import CustomerService from "./pages/CustomerService";
import CustomerServiceSent from "./pages/CustomerServiceSent";
import EmailVerified from "./pages/auth/EmailVerified";
import ForgotPassword from "./pages/auth/ForgotPassword";
import NotFound from "./pages/NotFound";
import KycApplication from "./pages/user/KycApplication";
import KycForm from "./pages/user/KycForm";
import KycThankYou from "./pages/user/KycThankYou";
import Login from "./pages/auth/Login";
import Profile from "./pages/user/Profile";
import Register from "./pages/auth/Register";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResetPassword from "./pages/auth/ResetPassword";
import SafetyPolicy from "./pages/SafetyPolicy";
import SignUpSuccess from "./pages/auth/SignUpSuccess";
import TermsConditions from "./pages/TermsConditions";
import Transactions from "./pages/user/Transactions";
import TransactionDetails from "./pages/user/TransactionDetails";
import User from "./pages/user/User";
import Withdraw from "./pages/user/Withdraw";
import Testimonials from "./pages/Testimonials";

function App() {
  const navigate = useNavigate();

  const handleOnIdle = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  useIdleTimer({
    timeout: 1200000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/activity", element: <Activity /> },
    { path: "/admin-kyc", element: <AdminKyc /> },
    { path: "/admin-kyc/:id", element: <AdminKycDetails /> },
    { path: "/admin-message", element: <AdminMessage /> },
    { path: "/admin-message/:id", element: <AdminMessageDetails /> },
    { path: "/admin-payment", element: <AdminPayment /> },
    { path: "/admin-transaction", element: <AdminTransaction /> },
    { path: "/admin-transaction/:id", element: <AdminTransactionDetails /> },
    { path: "/admin-user", element: <AdminUser /> },
    { path: "/admin-user/:id", element: <AdminUserDetails /> },
    { path: "/buy-shares", element: <BuyShares /> },
    { path: "/company-overview", element: <Company /> },
    { path: "/customer-service", element: <CustomerService /> },
    { path: "/customer-service-sent", element: <CustomerServiceSent /> },
    { path: "/email-verified", element: <EmailVerified /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/login", element: <Login /> },
    { path: "/kyc-application", element: <KycApplication /> },
    { path: "/kyc-form", element: <KycForm /> },
    { path: "/kyc-thank-you", element: <KycThankYou /> },
    { path: "/passwordreset/:resetToken", element: <ResetPassword /> },
    { path: "/profile", element: <Profile /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/register", element: <Register /> },
    { path: "/safety-policy", element: <SafetyPolicy /> },
    { path: "/signup-success", element: <SignUpSuccess /> },
    { path: "/terms-and-conditions", element: <TermsConditions /> },
    { path: "/testimonials", element: <Testimonials /> },
    { path: "/transactions", element: <Transactions /> },
    { path: "/transactions/:id", element: <TransactionDetails /> },
    { path: "/user", element: <User /> },
    { path: "/withdraw", element: <Withdraw /> },
    { path: "*", element: <NotFound /> },
  ]);

  return <div>{routes}</div>;
}

export default App;
