import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";

const TransactionDetails = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [transaction, setTransaction] = useState();
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    try {
      const getTransactionDetails = async () => {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/transaction-details",
          { id }
        );

        setTransaction(data);
      };

      getTransactionDetails();
    } catch (error) {}
  }, [id]);

  useEffect(() => {
    if (user && transaction) {
      setTimeout(() => {
        setPageLoading(false);
      }, 600);
    }
  }, [transaction, user]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "#1c65c9" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/activity" style={{ color: "#1c65c9" }}>
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "#1c65c9",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">Transaction Details</h4>
                <Link
                  to="/transactions"
                  className="btn btn-sm btn-auto btn-primary d-sm-block d-none"
                >
                  <em className="fas fa-arrow-left mr-3" />
                  Back
                </Link>
                <Link
                  to="/transactions"
                  className="btn btn-icon btn-sm btn-primary d-sm-none"
                >
                  <em className="fas fa-arrow-left" />
                </Link>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex">
                <div className="fake-class">
                  <span className="data-details-title">Transaction Date</span>
                  <span className="data-details-info">
                    {new Date(transaction?.createdAt).toLocaleString()}
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Transaction Status</span>
                  {transaction?.status === "Confirmed" && (
                    <span className="badge badge-success ucap">Confirmed</span>
                  )}
                  {transaction?.status === "Pending" && (
                    <span className="badge badge-warning ucap">Pending</span>
                  )}
                  {transaction?.status === "Rejected" && (
                    <span className="badge badge-danger ucap">Rejected</span>
                  )}
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Transaction ID</span>
                  <span className="data-details-info">
                    {transaction?.transactionId}
                  </span>
                </div>
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Transaction Info</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Transaction Type</div>
                  <div className="data-details-des">
                    {transaction?.type === "Deposit" && (
                      <strong>Purchase</strong>
                    )}
                    {transaction?.type === "Withdrawal" && (
                      <strong>Withdrawal</strong>
                    )}
                    {transaction?.type === "Credited" && (
                      <strong>Credited</strong>
                    )}
                  </div>
                </li>
                {transaction?.paymentMethod && (
                  <li>
                    <div className="data-details-head">Payment Method</div>
                    <div className="data-details-des">
                      <strong>{transaction?.paymentMethod}</strong>
                    </div>
                  </li>
                )}
              </ul>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Share Details</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Contribution</div>
                  <div className="data-details-des">
                    <span>
                      <strong>{transaction?.usd.toLocaleString()} USD</strong>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="data-details-head">Shares</div>
                  <div className="data-details-des">
                    <strong>
                      {transaction?.shares ? transaction.shares : "-"}
                    </strong>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
    </div>
  );
};

export default TransactionDetails;
