import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-light w-full text-gray font-sans text-center p-5 text-sm md:py-12">
      <ul className="hidden md:flex md:mx-auto md:w-[480px] md:justify-between md:mb-4 font-normal">
        <Link to="/terms-and-conditions">
          <li className="border-r-2 border-gray pr-8 text-blue font-semibold">
            TERMS & CONDITIONS
          </li>
        </Link>
        <Link to="/privacy-policy">
          <li className="border-r-2 border-gray pr-8 text-blue font-semibold">
            PRIVACY POLICY
          </li>
        </Link>
        <Link to="/safety-policy">
          <li className="text-blue font-semibold">SAFETY POLICY</li>
        </Link>
      </ul>
      <p>
        Copyright {new Date(Date.now()).getFullYear()} NextEra Energy, Inc. All
        rights reserved.
      </p>
      <div className="w-[115px] mt-8 h-0.5 bg-gray mx-auto" />
      <p className="text-xs">Delivered by Investis</p>
    </footer>
  );
};

export default Footer;
