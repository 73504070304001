import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";

const KycApplication = () => {
  return (
    <div className="page-user bg-light">
      <div className="page-ath-header">
        <Link to="/" className="page-ath-logo">
          <img src={logo} alt="logo" className="mx-auto" />
        </Link>
      </div>
      <div className="page-header page-header-kyc mt-3">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center">
              <h2 className="page-title">KYC Verification</h2>
              <p className="large">
                To comply with regulations, each participant is obligated to go
                through identity verification (KYC/AML) to prevent fraud cases.
                Please complete our fast and secure verification process to
                proceed with your registration process.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">
              <div className="kyc-status card mx-lg-4">
                <div className="card-innr">
                  <div className="status status-empty">
                    <div className="status-icon">
                      <em className="ti ti-files" />
                    </div>
                    <span className="status-text text-dark">
                      You have not submitted your necessary documents to verify
                      your identity, please verify your identity.
                    </span>
                    <Link to="/kyc-form" className="btn bg-green text-white">
                      Click here to complete your KYC
                    </Link>
                  </div>
                </div>
              </div>
              {/* .card */}
              <p className="text-light text-center">
                If you have any question, please contact our support team at
                <a href="mailto:contact@nexteraenergy.live">
                  {" "}
                  contact@nexteraenergy.live
                </a>
                .
              </p>
              <div className="gaps-1x" />
              <div className="gaps-3x d-none d-sm-block" />
            </div>
          </div>
        </div>
      </div>
      <ProfileFooter />
    </div>
  );
};

export default KycApplication;
