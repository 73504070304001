import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/nextera-logo.png";

const SignUpSuccess = () => {
  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-text">
            <h2 className="page-ath-heading">
              Thank you! <small>Your signup process is almost done.</small>
              <span className="text-success">
                Please check your mail and verify to proceed.
              </span>
              <p style={{ fontSize: "1.3rem", marginTop: "2rem" }}>
                Haven't received the email?
              </p>
              <span style={{ fontSize: "15px" }}>
                Make sure to check your spam folder.
              </span>
            </h2>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
              <li>
                &copy; {new Date(Date.now()).getFullYear()} NextEra Energy.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src={logo} alt="bgimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccess;
