import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SafetyPolicy = () => {
  return (
    <>
      <Header />
      <main className="mt-[78.22px] bg-white">
        <div className="text-center text-white bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h1 className="text-3xl md:text-4xl font-light text-white">
            Safety Policy
          </h1>
        </div>

        <div className="mt-8 px-5 md:py-5 md:px-7 lg:px-24 xl:px-52 text-base">
          <h2 className="text-2xl md:text-4xl font-light mt-4 tracking-wider text-black font-sans">
            The safety of our employees at work is a core value. No other
            business objective has higher priority.
          </h2>
          <p className="font-tinos text-gray mt-4">
            This Safety Policy is to guide all employees of NextEra Energy, Inc.
            and its affiliates (together, “NextEra Energy” or the “Company”) in
            pursuing their responsibility, shared with the Company, to safeguard
            the health and well being of everyone involved with NextEra Energy.
          </p>
          <p className="font-tinos text-gray mt-4">
            NextEra Energy recognizes that safety is beneficial to the employee,
            the family, the community, the customer and the Company. NextEra
            Energy is therefore committed to providing a safe and healthy work
            environment for all employees and requires that safety should not be
            compromised for any other business priority. We expect companies
            providing services to NextEra Energy to have the same high standards
            of safety and health as we do.
          </p>
          <p className="font-tinos text-gray mt-4">
            It is the responsibility of the Company to provide the facilities,
            equipment, tools, procedures, safety programs and training for
            employees to work injury free. It is the responsibility of each
            employee to work safely for the benefit of the individual as well as
            co-workers. This responsibility includes following appropriate
            safety rules and planning each work activity using appropriate risk
            assessment, good judgment and skills, along with a sincere
            dedication to work safely.
          </p>
          <p className="font-tinos text-gray mt-4">
            Employees should not start work until they understand what work is
            to be done and how to do it safely. They are all expected to be
            engaged in the Company’s Safety Management process. Engagement
            includes communicating with all the parties involved about
            safety-related behaviors and conditions, and bringing to
            management’s attention those issues which require their involvement
            and support.
          </p>
          <p className="font-tinos text-gray mt-4">
            All employees in leadership positions are responsible for deploying
            and actively supporting the Company’s overall Safety Program.
            Included in this responsibility are monitoring and enforcing the use
            of safe working practices and safety rules.
          </p>
          <p className="font-tinos text-gray mt-4 pb-8">
            All of us, through our leadership, commitment and engagement must
            accept the challenge to work safely and actively to pursue injury
            and illness prevention. Our employees, business partners, community
            and customers will all benefit. NextEra Energy encourages all
            employees to carry this strong health and safety value beyond the
            workplace to all of their activities.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SafetyPolicy;
