import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import Pagination from "../../components/Pagination";

const AdminKyc = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [kyc, setKyc] = useState([]);
  const [kycId, setKycId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [idToApprove, setIdtoApprove] = useState();
  const [idToReject, setIdtoReject] = useState();
  const [idToDelete, setIdToDelete] = useState();
  const [idToUnapprove, setIdToUnapprove] = useState();
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [unapproveModalOpen, setUnapproveModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForUser, setSearchingForUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = kyc?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(kyc?.length / recordsPerPage);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const approveKyc = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/approve-kyc", {
        idToApprove,
      });

      setLoading(false);
      setApproveModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const rejectKyc = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/reject-kyc", {
        idToReject,
      });

      setLoading(false);
      setRejectModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const deleteKyc = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/delete-kyc", {
        idToDelete,
      });

      setLoading(false);
      setDeleteModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const unapproveKyc = async () => {
    setLoading(true);

    try {
      await axios.put(
        "https://nextera-api-new.onrender.com/api/unapprove-kyc",
        {
          idToUnapprove,
        }
      );

      setLoading(false);
      setUnapproveModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    const getKyc = async () => {
      const { data } = await axios.get(
        "https://nextera-api-new.onrender.com/api/get-kyc"
      );
      setKyc(data);
    };

    getKyc();
  }, []);

  useEffect(() => {
    if (user && kyc) {
      setPageLoading(false);
      if (!user.isAdmin) {
        navigate("/profile");
      }
    }
  }, [user, kyc, navigate]);

  useEffect(() => {
    if (searchingForUser) {
      const foundUser = kyc?.filter((user) => {
        return Object.values(user.firstName)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchingForUser, searchTerm, kyc]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="content-area card">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">KYC List</h4>
              </div>
              <div className="row justify-content-between pdb-1x">
                <div className="col-9 col-sm-6 text-left py-2">
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <span className="input-icon input-icon-left ml-2.5">
                        <em className="ti ti-search absolute top-1/2 translate-y-[-50%]" />
                      </span>
                      <input
                        type="text"
                        className="pl-9 outline-0"
                        placeholder="Type in to Search"
                        onChange={(e) => {
                          setSearchingForUser(true);
                          setSearchTerm(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {!searchTerm && kyc?.length > 0 && (
                <table className="data-table dt-init kyc-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-user">User</th>
                      <th className="data-col dt-doc-type">Doc Type</th>
                      <th className="data-col dt-doc-front">Document</th>
                      <th className="data-col dt-doc-back">Phone</th>
                      <th className="data-col dt-status">Status</th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords.map((k) => (
                      <tr className="data-item" key={k._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">
                            {`${k.firstName} ${k.lastName}`}
                          </span>
                          <span className="sub user-id">{k.email}</span>
                        </td>
                        <td className="data-col dt-doc-type">
                          <span className="sub sub-s2 sub-dtype">{k.id}</span>
                        </td>
                        <td className="data-col dt-doc-front">
                          <a
                            href={k.document}
                            className="image-popup"
                            target="_blank"
                            rel="noreferrer"
                          >
                            View <em className="fas fa-download" />
                          </a>
                          &nbsp; &nbsp;
                        </td>
                        <td className="data-col dt-doc-back">
                          <span className="sub sub-s2 sub-dtype">
                            {k.number}
                          </span>
                        </td>
                        <td className="data-col dt-status">
                          {k.status === "Pending" && (
                            <span className="dt-status-md badge badge-outline badge-warning badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Pending" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                          {k.status === "Approved" && (
                            <span className="dt-status-md badge badge-outline badge-success badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Approved" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                          {k.status === "Rejected" && (
                            <span className="dt-status-md badge badge-outline badge-danger badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Rejected" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                          {k.status === "Unapproved" && (
                            <span className="dt-status-md badge badge-outline badge-warning badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Unapproved" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                        </td>
                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              onClick={() =>
                                setKycId((kycId) =>
                                  kycId === k._id ? null : k._id
                                )
                              }
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {kycId === k._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/admin-kyc/${k._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {k.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoApprove(k._id);
                                          setApproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check" /> Approve
                                      </a>
                                    </li>
                                  )}
                                  {k.status === "Unapproved" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoApprove(k._id);
                                          setApproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check" /> Approve
                                      </a>
                                    </li>
                                  )}
                                  {k.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoReject(k._id);
                                          setRejectModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Reject
                                      </a>
                                    </li>
                                  )}
                                  {k.status === "Approved" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToUnapprove(k._id);
                                          setUnapproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Unapprove
                                      </a>
                                    </li>
                                  )}
                                  <li>
                                    <a
                                      href="#/"
                                      onClick={() => {
                                        setIdToDelete(k._id);
                                        setDeleteModalOpen(true);
                                      }}
                                    >
                                      <em className="ti ti-trash" /> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {searchTerm && searchResults.length > 0 && (
                <table className="data-table dt-init kyc-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-user">User</th>
                      <th className="data-col dt-doc-type">Doc Type</th>
                      <th className="data-col dt-doc-front">Document</th>
                      <th className="data-col dt-doc-back">Phone</th>
                      <th className="data-col dt-status">Status</th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((k) => (
                      <tr className="data-item" key={k._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">
                            {`${k.firstName} ${k.lastName}`}
                          </span>
                          <span className="sub user-id">{k.email}</span>
                        </td>
                        <td className="data-col dt-doc-type">
                          <span className="sub sub-s2 sub-dtype">{k.id}</span>
                        </td>
                        <td className="data-col dt-doc-front">
                          <a
                            href={k.document}
                            className="image-popup"
                            target="_blank"
                            rel="noreferrer"
                          >
                            View <em className="fas fa-download" />
                          </a>
                          &nbsp; &nbsp;
                        </td>
                        <td className="data-col dt-doc-back">
                          <span className="sub sub-s2 sub-dtype">
                            {k.number}
                          </span>
                        </td>
                        <td className="data-col dt-status">
                          {k.status === "Pending" && (
                            <span className="dt-status-md badge badge-outline badge-warning badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Pending" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                          {k.status === "Approved" && (
                            <span className="dt-status-md badge badge-outline badge-success badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Approved" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                          {k.status === "Rejected" && (
                            <span className="dt-status-md badge badge-outline badge-danger badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Rejected" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                          {k.status === "Unapproved" && (
                            <span className="dt-status-md badge badge-outline badge-warning badge-md">
                              {k.status}
                            </span>
                          )}
                          {k.status === "Unapproved" && (
                            <span className="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">
                              {k.status.charAt(0)}
                            </span>
                          )}
                        </td>
                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              onClick={() =>
                                setKycId((kycId) =>
                                  kycId === k._id ? null : k._id
                                )
                              }
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {kycId === k._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/admin-kyc/${k._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {k.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoApprove(k._id);
                                          setApproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check" /> Approve
                                      </a>
                                    </li>
                                  )}
                                  {k.status === "Unapproved" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoApprove(k._id);
                                          setApproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check" /> Approve
                                      </a>
                                    </li>
                                  )}
                                  {k.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoReject(k._id);
                                          setRejectModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Reject
                                      </a>
                                    </li>
                                  )}
                                  {k.status === "Approved" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToUnapprove(k._id);
                                          setUnapproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Unapprove
                                      </a>
                                    </li>
                                  )}
                                  <li>
                                    <a
                                      href="#/"
                                      onClick={() => {
                                        setIdToDelete(k._id);
                                        setDeleteModalOpen(true);
                                      }}
                                    >
                                      <em className="ti ti-trash" /> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!searchTerm && kyc?.length === 0 && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    Nothing to show here
                  </p>
                </div>
              )}
              {!searchTerm && kyc?.length > 10 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
              {noResults && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    No results match your search
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {approveModalOpen && <div className="modal-backdrop fade show" />}
      {approveModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setApproveModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to approve this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Approving...
                  </button>
                ) : (
                  <button onClick={approveKyc} className="btn btn-primary">
                    Yes, Approve
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {rejectModalOpen && <div className="modal-backdrop fade show" />}
      {rejectModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setRejectModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to reject this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Rejecting...
                  </button>
                ) : (
                  <button onClick={rejectKyc} className="btn btn-primary">
                    Yes, Reject
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteModalOpen && <div className="modal-backdrop fade show" />}
      {deleteModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setDeleteModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to delete this record?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Deleting...
                  </button>
                ) : (
                  <button onClick={deleteKyc} className="btn btn-primary">
                    Yes, Delete
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {unapproveModalOpen && <div className="modal-backdrop fade show" />}
      {unapproveModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setUnapproveModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to unapprove this record?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Unapproving...
                  </button>
                ) : (
                  <button onClick={unapproveKyc} className="btn btn-primary">
                    Yes, Unapprove
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminKyc;
