import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import bottomLogo from "../assets/bottom-logo.svg";
import fpl from "../assets/fpl.png";
import neer from "../assets/neer.gif";

const Company = () => {
  return (
    <>
      <Header />
      <main className="mt-[78.22px]" style={{ background: "white" }}>
        <div className="text-center bg-green p-9 md:py-16 md:px-24 lg:py-20 lg:px-48 xl:px-72 font-sans">
          <h1 className="text-3xl md:text-4xl font-light text-white">
            Company Overview
          </h1>
        </div>

        <div className="mt-8 px-5 md:py-5 md:px-7 lg:px-24 font-tinos text-gray xl:px-52">
          <p className="text-base">
            NextEra Energy, Inc. (NYSE: NEE) is a leading clean energy company
            headquartered Florida, USA. NextEra Energy owns Florida Power &amp;
            Light Company, which is America’s largest electric utility that
            sells more power than any other utility, providing clean,
            affordable, reliable electricity to more than 5.7 million customer
            accounts, supporting more than 12 million residents across Florida.
            NextEra Energy also owns a competitive clean energy business,
            NextEra Energy Resources, LLC, which, together with its affiliated
            entities, is the world's largest generator of renewable energy from
            the wind and sun and a world leader in battery storage. Through its
            subsidiaries, NextEra Energy generates clean, emissions-free
            electricity from seven commercial nuclear power units in Florida,
            New Hampshire and Wisconsin. A Fortune 200 company, NextEra Energy
            has been recognized often by third parties for its efforts in
            sustainability, corporate responsibility, ethics and compliance, and
            diversity. NextEra Energy is ranked No. 1 in the electric and gas
            utilities industry on Fortune's 2022 list of "World's Most Admired
            Companies," recognized on Fortune’s 2021 list of companies that
            “Change the World” and received the S&amp;P Global Platts 2020
            Energy Transition Award for leadership in environmental, social and
            governance.&nbsp;
          </p>

          <div>
            <img src={bottomLogo} alt="logo" className="w-[147px] my-6 pl-3" />
            <img src={fpl} alt="fpl_logo" className="mb-4 pl-3" />
            <img src={neer} alt="neer" className="pb-9 pl-3" />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Company;
