import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/nextera-logo.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <header className="fixed top-0 left-0 bg-white w-full p-5 md:p-7 xl:px-32 z-50">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            className="w-[86px] md:w-28 lg:w-32 xl:w-36"
          />
        </Link>

        <nav className="flex items-center">
          {menuOpen ? (
            <button
              onClick={toggleMenu}
              className="bg-close w-6 h-5 bg-no-repeat md:hidden z-20"
            />
          ) : (
            <button
              onClick={toggleMenu}
              className="bg-hamburger w-6 h-5 bg-no-repeat md:hidden"
            />
          )}
        </nav>

        <nav className="hidden md:flex">
          <ul className="flex items-center justify-between w-[28rem] uppercase font-sans text-nav font-semibold tracking-[1px] text-[10px] lg:text-[13px] lg:w-[40rem]">
            <Link to="/company-overview" className="hover:text-black">
              <li>Our Company</li>
            </Link>
            <Link to="/customer-service" className="hover:text-black">
              <li>Customer Service</li>
            </Link>
            <Link to="/testimonials" className="hover:text-black">
              <li>Testimonials</li>
            </Link>
            <Link to="/profile" className="hover:text-black">
              <li>Investor Portal</li>
            </Link>
          </ul>
        </nav>
      </div>

      {menuOpen && (
        <div className="fixed h-screen w-full top-0 left-0 bg-white font-sans md:hidden">
          <ul className="relative top-[16%] text-right pr-5 text-4xl font-light">
            <Link to="/company-overview" onClick={toggleMenu}>
              <li className="mb-4">Our Company</li>
            </Link>
            <Link to="/customer-service" onClick={toggleMenu}>
              <li className="mb-4">Customer Service</li>
            </Link>
            <Link to="/testimonials" onClick={toggleMenu}>
              <li className="mb-4">Testimonials</li>
            </Link>
            <Link to="/profile" onClick={toggleMenu}>
              <li className="mb-4">Investor Portal</li>
            </Link>
          </ul>

          <ul className="relative top-[25%] text-right pr-5 font-semibold text-blue">
            <Link to="/terms-and-conditions" onClick={toggleMenu}>
              <li className="mb-1.5">TERMS & CONDITIONS</li>
            </Link>
            <Link to="/privacy-policy" onClick={toggleMenu}>
              <li className="mb-1.5">PRIVACY POLICY</li>
            </Link>
            <Link to="/safety-policy" onClick={toggleMenu}>
              <li className="mb-1.5">SAFETY POLICY</li>
            </Link>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
