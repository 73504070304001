import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";
import ProfileFooter from "../../components/ProfileFooter";
import StockContext from "../../store/stock-context";

const AdminUserDetails = () => {
  const [dropdownOpen, setDropdownopen] = useState(false);
  const [dropdownDisplay, setDropdownDisplay] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expired, setExpired] = useState(false);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [userRecord, setUserRecord] = useState();
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [unblockModalOpen, setUnblockModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revenue, setRevenue] = useState("");
  const [editRevenue, setEditRevenue] = useState("");
  const [revenueModalOpen, setRevenueModalOpen] = useState(false);
  const [revenueInputModalOpen, setRevenueInputModalOpen] = useState(false);
  const [editRevenueModalOpen, setEditRevenueModalOpen] = useState(false);
  const [editRevenueInputModalOpen, setEditRevenueInputModalOpen] =
    useState(false);
  const [shareOutcome, setShareOutcome] = useState("");
  const [shareInputModalOpen, setShareInputModalOpen] = useState(false);
  const [shares, setShares] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [userBalance, setUserBalance] = useState("");
  const [balanceInputModalOpen, setBalanceInputModalOpen] = useState(false);
  const [depositInputModalOpen, setDepositInputModalOpen] = useState(false);
  const [deposit, setDeposit] = useState("");
  const [balanceModalOpen, setBalanceModalOpen] = useState(false);
  const [depositModalOpen, setDepositModalOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const ctx = useContext(StockContext);

  const toggleDropdown = () => {
    setDropdownopen((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const logout = () => {
    localStorage.removeItem("authToken-nextera");
    navigate("/login");
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const toggleOptions = () => {
    setOptionsOpen((prevState) => !prevState);
  };

  const blockUser = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/block-user", {
        idToBlock: id,
      });

      setLoading(false);
      setBlockModalOpen(false);
      navigate("/admin-user");
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/delete-user", {
        idToDelete: id,
      });

      setLoading(false);
      setDeleteModalOpen(false);
      navigate("/admin-user");
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const unblockUser = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/unblock-user", {
        idToUnblock: id,
      });

      setLoading(false);
      setUnblockModalOpen(false);
      navigate("/admin-user");
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const addRevenue = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/add-revenue", {
        revenue,
        shares: shareOutcome,
        userId: id,
        userEmail: user?.email,
        userName: user?.name,
      });

      setLoading(false);
      setRevenueModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const adjustRevenue = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/edit-revenue", {
        revenue: editRevenue,
        userId: id,
      });

      setLoading(false);
      setEditRevenueModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const editShares = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/edit-shares", {
        shares,
        userId: id,
      });

      setLoading(false);
      setShareModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const editBalance = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/edit-balance", {
        balance: userBalance,
        userId: id,
      });

      setLoading(false);
      setBalanceModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  const editDeposit = async () => {
    setLoading(true);

    try {
      await axios.put("https://nextera-api-new.onrender.com/api/edit-deposit", {
        deposit,
        userId: id,
      });

      setLoading(false);
      setDepositModalOpen(false);
      window.location.reload();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownDisplay({ display: "block" });
    } else {
      setDropdownDisplay({ display: "none" });
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (!localStorage.getItem("authToken-nextera")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (expired) {
      localStorage.removeItem("authToken-nextera");
      navigate("/login");
    }
  }, [expired, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken-nextera");

    const getUser = async () => {
      try {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user",
          {
            token,
          }
        );

        setUser(data);
        setBalance(data.balance);
        setEditRevenue(data.revenue);
        setShares(data.shares);
        setUserBalance(data.balance);

        if (data === "expired") {
          setExpired(true);
        }
      } catch (error) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    try {
      const getUserDetails = async () => {
        const { data } = await axios.post(
          "https://nextera-api-new.onrender.com/api/user-details",
          { id }
        );

        setUserRecord(data);
      };

      getUserDetails();
    } catch (error) {}
  }, [id]);

  useEffect(() => {
    if (user && userRecord) {
      setPageLoading(false);
      if (!user.isAdmin) {
        navigate("/profile");
      }
    }
  }, [user, userRecord, navigate]);

  useEffect(() => {
    setShareOutcome(Math.round(revenue / ctx.stockPrice));
  }, [ctx.stockPrice, revenue]);

  return (
    <div className="page-user">
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {!mobileMenuOpen ? (
                    <button className="toggle-nav" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  ) : (
                    <button className="toggle-nav active" onClick={toggleMenu}>
                      <div className="toggle-icon">
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
              <Link className="topbar-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {user?.name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    onClick={toggleDropdown}
                  >
                    <em className="ti ti-user" />
                  </button>
                  <div
                    className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown w-[207px]"
                    style={dropdownDisplay}
                  >
                    <div className="user-status">
                      <h6 className="user-status-title">Active balance</h6>
                      <div className="user-status-balance">
                        ${balance?.toLocaleString()}
                      </div>
                    </div>
                    <ul className="user-links">
                      <li>
                        <Link to="/user" style={{ color: "rgb(91, 163, 60)" }}>
                          <i className="ti ti-id-badge" />
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/activity"
                          style={{ color: "rgb(91, 163, 60)" }}
                        >
                          <i className="ti ti-eye" />
                          Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-links bg-light">
                      <li onClick={logout}>
                        <button
                          style={{
                            color: "rgb(91, 163, 60)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <i className="ti ti-power-off mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="navbar navbar-mobile active">
            <div className="container">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li
                      className={`has-dropdown page-links-all ${
                        mobileAccordionOpen && "current"
                      }`}
                    >
                      <a
                        className="drop-toggle"
                        href="#/"
                        onClick={toggleAccordion}
                      >
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul
                        className="navbar-dropdown text-green"
                        style={{
                          display: `${mobileAccordionOpen ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="navbar">
            <div className="container lg:max-w-[960px] xl:max-w-[1140px]">
              <div className="navbar-innr">
                <ul className="navbar-menu">
                  <li>
                    <Link to="/profile">
                      <em className="ikon ikon-dashboard" /> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-shares">
                      <em className="ikon ikon-coins" /> Buy Shares
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw">
                      <em className="ikon ikon-distribution" /> Withdrawals
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">
                      <em className="ikon ikon-transactions" /> Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <em className="ikon ikon-user" /> Profile
                    </Link>
                  </li>
                  {user?.isAdmin && (
                    <li className="has-dropdown page-links-all">
                      <a className="drop-toggle" href="#/">
                        <em className="ikon ikon-exchange" /> Admin
                      </a>
                      <ul className="navbar-dropdown text-green">
                        <li>
                          <Link to="/admin-user">Users</Link>
                          <Link to="/admin-transaction">Transactions</Link>
                          <Link to="/admin-payment">Payment Details</Link>
                          <Link to="/admin-kyc">Kyc Verification</Link>
                          <Link to="/admin-message">Messages</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                <ul className="navbar-btns">
                  {user?.kycStatus === "Approved" ? (
                    <li>
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x" />
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="/kyc-application"
                        className="btn btn-sm btn-outline btn-light"
                      >
                        <em className="text-primary ti ti-files" />
                        <span>KYC Application</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="card content-area">
            <div className="card-innr card-innr-fix">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">User Details</h4>
                <div className="d-flex align-items-center guttar-20px">
                  <div className="flex-col d-sm-block d-none">
                    <Link
                      to="/admin-user"
                      className="btn btn-sm btn-auto btn-primary"
                    >
                      <em className="fas fa-arrow-left mr-3" />
                      Back
                    </Link>
                  </div>
                  <div className="flex-col d-sm-none">
                    <Link
                      to="/admin-user"
                      className="btn btn-icon btn-sm btn-primary"
                    >
                      <em className="fas fa-arrow-left" />
                    </Link>
                  </div>
                  <div className="relative d-inline-block">
                    <button
                      onClick={toggleOptions}
                      className="btn btn-dark btn-sm btn-icon toggle-tigger"
                    >
                      <em className="ti ti-more-alt" />
                    </button>
                    {optionsOpen && (
                      <div className="toggle-class dropdown-content dropdown-content-top-left active">
                        <ul className="dropdown-list">
                          <li>
                            <a
                              href="#/"
                              onClick={() => setRevenueInputModalOpen(true)}
                            >
                              <em className="fas fa-shield-alt" /> Add Revenue
                            </a>
                          </li>
                          <li>
                            <a
                              href="#/"
                              onClick={() => setEditRevenueInputModalOpen(true)}
                            >
                              <em className="fas fa-shield-alt" /> Edit Revenue
                            </a>
                          </li>
                          <li>
                            <a
                              href="#/"
                              onClick={() => setShareInputModalOpen(true)}
                            >
                              <em className="fas fa-shield-alt" /> Edit Shares
                            </a>
                          </li>
                          <li>
                            <a
                              href="#/"
                              onClick={() => setBalanceInputModalOpen(true)}
                            >
                              <em className="fas fa-shield-alt" /> Edit Balance
                            </a>
                          </li>
                          <li>
                            <a
                              href="#/"
                              onClick={() => setDepositInputModalOpen(true)}
                            >
                              <em className="fas fa-shield-alt" /> Edit Deposit
                            </a>
                          </li>
                          {userRecord?.isBlocked ? (
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setUnblockModalOpen(true);
                                }}
                              >
                                <em className="fas fa-ban" /> Unblock
                              </a>
                            </li>
                          ) : (
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setBlockModalOpen(true);
                                }}
                              >
                                <em className="fas fa-ban" /> Block
                              </a>
                            </li>
                          )}
                          <li>
                            <a
                              href="#/"
                              onClick={() => {
                                setDeleteModalOpen(true);
                              }}
                            >
                              <em className="fas fa-trash" /> Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex">
                <div className="fake-class">
                  <span className="data-details-title">Deposit</span>
                  <span className="data-details-info large">
                    {userRecord?.deposit?.toLocaleString()} <small>USD</small>
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Revenue</span>
                  <span className="data-details-info large">
                    {userRecord?.revenue?.toLocaleString()} <small>USD</small>
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Balance</span>
                  <span className="data-details-info large">
                    {userRecord?.balance?.toLocaleString()} <small>USD</small>
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Shares</span>
                  <span className="data-details-info large">
                    {userRecord?.shares}
                  </span>
                </div>
                <ul className="data-vr-list">
                  <li>
                    <div className="data-state data-state-sm data-state-approved" />
                    Email
                  </li>
                  {(userRecord?.kycStatus === "Pending" ||
                    userRecord?.kycStatus === "Unapproved" ||
                    userRecord?.kycStatus === "Unverified") && (
                    <li>
                      <div className="data-state data-state-sm data-state-pending" />
                      KYC
                    </li>
                  )}
                  {userRecord?.kycStatus === "Approved" && (
                    <li>
                      <div className="data-state data-state-sm data-state-approved" />
                      KYC
                    </li>
                  )}
                  {userRecord?.kycStatus === "Rejected" && (
                    <li>
                      <div className="data-state data-state-sm data-state-canceled" />
                      KYC
                    </li>
                  )}
                </ul>
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">User Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Full Name</div>
                  <div className="data-details-des">{userRecord?.name}</div>
                </li>
                <li>
                  <div className="data-details-head">Email Address</div>
                  <div className="data-details-des">{userRecord?.email}</div>
                </li>
                <li>
                  <div className="data-details-head">Mobile Number</div>
                  <div className="data-details-des">{userRecord?.number}</div>
                </li>
                {userRecord?.plan && (
                  <li>
                    <div className="data-details-head">Plan</div>
                    <div className="data-details-des">{userRecord?.plan}</div>
                  </li>
                )}
                {userRecord?.ssn && (
                  <li>
                    <div className="data-details-head">SSN</div>
                    <div className="data-details-des">{userRecord?.ssn}</div>
                  </li>
                )}
              </ul>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">More Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Joining Date</div>
                  <div className="data-details-des">
                    {new Date(userRecord?.createdAt).toLocaleDateString()}
                  </div>
                </li>
                <li>
                  <div className="data-details-head">Reg Method</div>
                  <div className="data-details-des">Email</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ProfileFooter />
      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="rgb(91, 163, 60)"
      />
      <ToastContainer />
      {blockModalOpen && <div className="modal-backdrop fade show" />}
      {blockModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setBlockModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to block this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Blocking...
                  </button>
                ) : (
                  <button onClick={blockUser} className="btn btn-primary">
                    Yes, Block
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {unblockModalOpen && <div className="modal-backdrop fade show" />}
      {unblockModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setUnblockModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to unblock this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Unblocking...
                  </button>
                ) : (
                  <button onClick={unblockUser} className="btn btn-primary">
                    Yes, Unblock
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteModalOpen && <div className="modal-backdrop fade show" />}
      {deleteModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setDeleteModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to delete this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Deleting...
                  </button>
                ) : (
                  <button onClick={deleteUser} className="btn btn-primary">
                    Yes, Delete
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {revenueInputModalOpen && <div className="modal-backdrop fade show" />}
      {revenueInputModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setRevenueInputModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Input amount(USD)</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="number"
                  value={revenue}
                  onChange={(e) => {
                    setRevenue(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setRevenueInputModalOpen(false);
                    setRevenueModalOpen(true);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {revenueModalOpen && <div className="modal-backdrop fade show" />}
      {revenueModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setRevenueModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to add ${revenue} and {shareOutcome}{" "}
                  shares to this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button onClick={addRevenue} className="btn btn-primary">
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {editRevenueInputModalOpen && (
        <div className="modal-backdrop fade show" />
      )}
      {editRevenueInputModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setEditRevenueInputModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Edit amount(USD)</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="number"
                  defaultValue={editRevenue}
                  onChange={(e) => {
                    setEditRevenue(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setEditRevenueInputModalOpen(false);
                    setEditRevenueModalOpen(true);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {editRevenueModalOpen && <div className="modal-backdrop fade show" />}
      {editRevenueModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setEditRevenueModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to set this user's revenue to $
                  {editRevenue}?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button onClick={adjustRevenue} className="btn btn-primary">
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {shareInputModalOpen && <div className="modal-backdrop fade show" />}
      {shareInputModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setShareInputModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Edit shares</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="number"
                  defaultValue={shares}
                  onChange={(e) => {
                    setShares(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setShareInputModalOpen(false);
                    setShareModalOpen(true);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {shareModalOpen && <div className="modal-backdrop fade show" />}
      {shareModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setShareModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to set this user's shares to {shares}{" "}
                  shares?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button onClick={editShares} className="btn btn-primary">
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
      {balanceInputModalOpen && <div className="modal-backdrop fade show" />}
      {balanceInputModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setBalanceInputModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Edit Balance(USD)</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="number"
                  defaultValue={userBalance}
                  onChange={(e) => {
                    setUserBalance(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setBalanceInputModalOpen(false);
                    setBalanceModalOpen(true);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {depositInputModalOpen && <div className="modal-backdrop fade show" />}
      {depositInputModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setDepositInputModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Edit Deposit(USD)</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="number"
                  defaultValue={deposit}
                  onChange={(e) => {
                    setDeposit(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setDepositInputModalOpen(false);
                    setDepositModalOpen(true);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {balanceModalOpen && <div className="modal-backdrop fade show" />}
      {balanceModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setBalanceModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to set this user's balance to $
                  {userBalance}?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button onClick={editBalance} className="btn btn-primary">
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {depositModalOpen && <div className="modal-backdrop fade show" />}
      {depositModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setDepositModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to set this user's deposit to ${deposit}
                  ?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button onClick={editDeposit} className="btn btn-primary">
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUserDetails;
