import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showSuccess = () => {
    toast.success("Email sent successfully", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }
    setLoading(true);

    try {
      await axios.post(
        "https://nextera-api-new.onrender.com/api/auth/forgotpassword",
        { email },
        config
      );

      setLoading(false);
      showSuccess();
      setEmail("");
    } catch (error) {
      setLoading(false);
      showError();
      console.log(error);
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Reset password
              <span>
                If you forgot your password, we’ll email you instructions to
                reset your password.
              </span>
            </h2>
            <form onSubmit={forgotPasswordHandler}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="input-bordered"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailFieldEmpty(false);
                  }}
                />
                {emailFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    Please enter a valid email address
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {loading ? (
                    <button className="btn btn-primary btn-block" disabled>
                      Sending...
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block">
                      Send Reset Link
                    </button>
                  )}
                </div>
                <div>
                  <Link to="/login">Return to Login</Link>
                </div>
              </div>
              <div className="gaps-2x" />
            </form>
          </div>

          <ToastContainer />

          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
              <li>
                &copy; {new Date(Date.now()).getFullYear()} NextEra Energy.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src={logo} alt="bgimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
