import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import logo from "../../assets/nextera-logo.png";

const Register = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("authToken-nextera")) {
      navigate("/profile");
    }
  }, [navigate]);

  const showUserExistsError = () => {
    toast.error("This user already exists", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const showError = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [numberFieldEmpty, setNumberFieldEmpty] = useState(false);
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] =
    useState(false);
  const [agreed, setAgreed] = useState(false);
  const [agreedUnchecked, setAgreedUnchecked] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const toggleCheckBox = () => {
    setAgreed((prevState) => !prevState);
  };

  const registerHandler = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (!name) {
      setNameFieldEmpty(true);
      return;
    } else {
      setNameFieldEmpty(false);
    }

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!number) {
      setNumberFieldEmpty(true);
      return;
    } else {
      setNumberFieldEmpty(false);
    }

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    if (password.length < 6) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordFieldEmpty(true);
      return;
    } else {
      setConfirmPasswordFieldEmpty(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    if (!agreed) {
      setAgreedUnchecked(true);
      return;
    } else {
      setAgreedUnchecked(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://nextera-api-new.onrender.com/api/auth/register",
        { name, email, number, password },
        config
      );

      setLoading(false);
      if (data.message === "Verification email sent") {
        navigate("/signup-success");
      } else {
        showError();
        setLoading(false);
      }
    } catch (error) {
      if (error.response.data.error === "This user already exists") {
        showUserExistsError();
        setLoading(false);
        return;
      }

      setLoading(false);
      showError();
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Sign up <small>Create New Brokerage Account</small>
            </h2>
            <form onSubmit={registerHandler}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="input-bordered"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameFieldEmpty(false);
                  }}
                />
                {nameFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
              </div>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Email address"
                  className="input-bordered"
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                    setEmailFieldEmpty(false);
                  }}
                />
                {emailFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    Please enter a valid email address
                  </p>
                )}
              </div>
              <div className="input-item">
                <PhoneInput
                  className="input-bordered outline-0"
                  placeholder="Phone Number"
                  value={number}
                  onChange={(number) => setNumber(number)}
                />
                {numberFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    Please enter your phone number
                  </p>
                )}
              </div>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="Password"
                  className="input-bordered"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordFieldEmpty(false);
                    setPasswordWeak(false);
                  }}
                />
                {passwordFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
                {passwordWeak && (
                  <p className="mt-3 text-red text-sm">
                    Password should be at least 6 characters
                  </p>
                )}
              </div>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="input-bordered"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordFieldEmpty(false);
                  }}
                />
                {confirmPasswordFieldEmpty && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
                {passwordError && (
                  <p className="mt-3 text-red text-sm">
                    Passwords do not match
                  </p>
                )}
              </div>
              <div className="input-item text-left">
                <input
                  className="input-checkbox input-checkbox-md"
                  id="term-condition"
                  type="checkbox"
                  value={agreed}
                  onChange={toggleCheckBox}
                />
                <label htmlFor="term-condition">
                  I agree to NextEra Energy’s
                  <strong>
                    <Link to="/privacy-policy"> Privacy Policy</Link>
                  </strong>{" "}
                  &amp;
                  <strong>
                    <Link to="/terms-and-conditions"> Terms.</Link>
                  </strong>
                </label>
                {agreedUnchecked && (
                  <p className="mt-3 text-red text-sm">
                    This field is required
                  </p>
                )}
              </div>
              <ToastContainer />
              {loading ? (
                <button className="btn btn-primary btn-block" disabled>
                  Creating Account...
                </button>
              ) : (
                <button className="btn btn-primary btn-block">
                  Create Account
                </button>
              )}
            </form>
            <div className="gaps-2x"></div>
            <div className="gaps-2x"></div>
            <div className="form-note">
              Already have an account ?
              <Link to="/login">
                {" "}
                <strong>Sign In</strong>
              </Link>
            </div>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
              <li>
                &copy; {new Date(Date.now()).getFullYear()} NextEra Energy.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src={logo} alt="bgimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
