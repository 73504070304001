import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CustomerServiceSent = () => {
  return (
    <>
      <Header />
      <div className="page-user" style={{ marginTop: "78.22px" }}>
        <div className="page-content">
          <div
            className="container"
            style={{ paddingTop: "10rem", paddingBottom: "15rem" }}
          >
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="card content-area">
                  <div className="card-innr">
                    <div className="status status-thank px-md-5">
                      <div className="status-icon">
                        <em className="ti ti-check"></em>
                      </div>
                      <span className="status-text large text-dark">
                        Thank you for submitting form
                      </span>
                      <p className="px-md-5">
                        We received your data, our team will check and get back
                        to you via email.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CustomerServiceSent;
