import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/nextera-logo.png";

const EmailVerified = () => {
  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div class="page-ath-text">
            <div class="alert alert-success">
              Your email is successfully verified.
            </div>
            <div class="gaps-0-5x"></div>
            <Link to="/kyc-application" class="btn btn-primary">
              Proceed <em className="fas fa-arrow-right ml-3" />
            </Link>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
              <li>
                &copy; {new Date(Date.now()).getFullYear()} NextEra Energy.
              </li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src={logo} alt="bgimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
